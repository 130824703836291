import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DesktopContainer, MobileContainer, FlagSection } from 'Components';
import { palette } from 'Context';
import { logoPNG } from 'Images';

const DesktopStyle = styled.section`
  h1 {
    margin-bottom: 2rem;
  }
  a {
    text-decoration: none;
    color: ${palette.blueText};
  }
`;

const MobileStyle = styled.section`
  header {
    margin-bottom: 20vh;
    border-bottom: 1px solid ${palette.blueDark};
    p {
      font-family: ${(props) => props.theme.font.family.commutersSans};
    }
  }

  text-align: center;
  h1 {
    margin-bottom: 2rem;
  }
  a {
    text-decoration: none;
    color: ${palette.blueText};
  }
  display: flex;
  justify-content: center;
  height: 80vh;
`;

const LogoHeader = styled.img.attrs(() => ({
  src: logoPNG,
  alt: '🇺🇸 Populist',
  width: '30%',
}))`
  margin: 1rem 0 0 0;
`;

export default function NotFound404(): JSX.Element {
  return (
    <>
      <MobileContainer>
        <MobileStyle>
          <div>
            <header>
              <LogoHeader />
              <p>404 Error</p>
            </header>
            <h1>Sorry, that page doesn&#39;t exist.</h1>
            <p>
              Return to the <Link to="/">Populist home page.</Link>
            </p>
          </div>
        </MobileStyle>
      </MobileContainer>
      <DesktopContainer>
        <FlagSection colorTheme="darkBlue" title="404 ERROR">
          <DesktopStyle>
            <h1>Sorry, that page doesn&#39;t exist.</h1>
            <p>
              Return to the <Link to="/">Populist home page</Link>
            </p>
          </DesktopStyle>
        </FlagSection>
      </DesktopContainer>
    </>
  );
}
