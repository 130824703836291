import React from 'react';
import styled, { css } from 'styled-components';
import {
  TitleSection,
  GapContainer,
  DividerContainer,
  Em,
  Txt,
  Row,
  UpDownVotes,
  UpDownVotesProps,
  UpDownVoteDemo,
  SectionHeader,
} from 'Components';
import { usePagination } from 'Hooks/usePagination';

export interface ContributionProps {
  id: string;
  amount: number;
  industry: string;
  type?: 'sector' | 'industry';
  upDownVotes?: UpDownVotesProps;
}

export interface FinancialsProps {
  totalRaised: number;
  individualContributions: number;
  contributions: ContributionProps[];
}

const Amount = styled.div(({ theme }) => {
  const { font, color } = theme;
  return css`
    font-family: ${font.family.commutersSans};
    font-size: 1.125rem;
    line-height: 1.5;
    color: ${color.text.primary};
  `;
});

function Contribution(props: ContributionProps) {
  const { amount, industry, upDownVotes } = props;

  return (
    <Row>
      <Txt>
        ${amount.toLocaleString()}
        <Em style={{ marginLeft: '2rem' }}>{industry}</Em>
      </Txt>

      {upDownVotes ? <UpDownVotes {...upDownVotes} /> : <UpDownVoteDemo />}
    </Row>
  );
}

export function Financials(props: FinancialsProps): JSX.Element {
  const { totalRaised, contributions } = props;

  const {
    nextPage,
    prevPage,
    setCurrentPage,
    currentPage,
    pageCount,
    currentData,
    sortData,
    items,
  } = usePagination<ContributionProps>(contributions, 3);

  const sortByHighest = () => {
    sortData((a, b) => (a.amount < b.amount ? 1 : -1));
  };

  // const sortByMostVotes = () => {
  //   sortData((a, b) =>
  //     (a.upDownVotes?.upVotes || 0) > (b.upDownVotes?.upVotes || 0) ? 1 : -1
  //   );
  // };

  const sortByIndustry = () => {
    sortData((a, b) => (a.industry < b.industry ? 1 : -1));
  };

  const $header = (
    <SectionHeader
      paginationProps={{
        pageCount,
        currentPage,
        setCurrentPage,
        nextPage,
        prevPage,
        items,
      }}
      sortOptions={[
        {
          text: `Highest`,
          id: `financials-sort-highest`,
          callback: sortByHighest,
        },
        {
          text: 'Industry',
          id: 'financials-sort-industry',
          callback: sortByIndustry,
        },
      ]}
    />
  );

  return (
    <TitleSection
      title="Financials"
      colorTheme="green"
      header={$header}
      {...{ nextPage, prevPage }}
    >
      <GapContainer direction="column" gap="1rem">
        <Row margin="0 0 0 2rem">
          <Txt>Total Raised</Txt>
          <Amount>${totalRaised.toLocaleString()}</Amount>
        </Row>

        {/* <Row margin="0 0 0 2rem">
          <Txt>
            From Individual Contributions <Em>(Less than $200)</Em>
          </Txt>
          <Amount>${individualContributions.toLocaleString()}</Amount>
        </Row> */}

        <DividerContainer>
          {currentData().map((contribution) => (
            <Contribution {...contribution} key={contribution.id} />
          ))}
        </DividerContainer>
      </GapContainer>
    </TitleSection>
  );
}
