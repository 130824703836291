import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { BallotMeasureDetail, Organization, palette } from 'Context';
import {
  DesktopContainer,
  Draggable,
  MobileContainer,
  MobileTabSwitcher,
  OrganizationCard,
  PageNavigation,
  PageIndex,
} from 'Components';

import { usePagination } from 'Hooks/usePagination';

const EndorsementsContainer = styled.div(({ theme: { color } }) => {
  return css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

    &&& {
      h3 {
        font-size: 1.5em;
        text-transform: uppercase;
        margin: 0 0 2rem;

        span {
          background-color: ${color.background.secondary};
          font-family: 'Proxima Nova';
          font-size: 1em;
          border-radius: 2em;
          max-height: 1em;
          margin-left: 1rem;
          text-align: center;
          padding: 0.5rem 1rem 0.4rem;
          vertical-align: middle;
        }
      }
    }
  `;
});

export default function BallotMeasureEndorsements(props: {
  measure: BallotMeasureDetail;
}): JSX.Element {
  const { measure } = props;

  const [selectedTab, setSelectedTab] = useState('SUPPORTING');

  const [visibleEndorsements, setVisibleEndorsements] = useState(
    measure.supporters
  );

  const {
    nextPage,
    prevPage,
    setCurrentPage,
    currentPage,
    pageCount,
    currentData,
  } = usePagination<Organization>(visibleEndorsements ?? [], 6);

  const handleTabClick = (selected: string) => {
    setSelectedTab(selected);
    setCurrentPage(1);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDrag = (mx: number, my: number) => {
    if (mx < -100) {
      nextPage();
    }
    if (mx > 100) {
      prevPage();
    }
  };

  useEffect(() => {
    if (selectedTab === 'SUPPORTING') {
      setVisibleEndorsements(measure.supporters);
    }
    if (selectedTab === 'OPPOSING') {
      setVisibleEndorsements(measure.opposers);
    }
  }, [selectedTab, measure]);

  return (
    <>
      <MobileContainer>
        <MobileTabSwitcher
          tabs={[
            {
              label: 'supporting',
              count: measure.supporters.length,
              selected: selectedTab === 'SUPPORTING',
              color: palette.greenSupport,
            },
            {
              label: 'opposing',
              count: measure.opposers.length,
              selected: selectedTab === 'OPPOSING',
              color: palette.red,
            },
          ]}
          handleTabSelect={handleTabClick}
        />
        <PageIndex
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          items={[]}
          mode="dots"
        />
        {!currentData().length && <OrganizationCard />}
        <Draggable key={Math.random()} handleDrag={handleDrag}>
          <>
            {visibleEndorsements.map((org) => (
              <OrganizationCard
                key={org.name}
                organization={org}
                supporting={selectedTab === 'SUPPORTING'}
                opposing={selectedTab === 'OPPOSING'}
              />
            ))}
          </>
        </Draggable>
        <PageNavigation prevPage={prevPage} nextPage={nextPage} />
      </MobileContainer>
      <DesktopContainer>
        <EndorsementsContainer>
          <div>
            <h3>
              Supporting <span>{measure.supporters.length}</span>
            </h3>
            {!measure.supporters.length && <OrganizationCard />}
            {measure.supporters.map((org) => (
              <OrganizationCard key={org.name} organization={org} supporting />
            ))}
          </div>
          <div>
            <header>
              <h3>
                Opposing <span>{measure.opposers.length}</span>
              </h3>
            </header>
            {!measure.opposers.length && <OrganizationCard />}
            {measure.opposers.map((org) => (
              <OrganizationCard key={org.name} organization={org} opposing />
            ))}
          </div>
        </EndorsementsContainer>
      </DesktopContainer>
    </>
  );
}
