import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import {
  Avatar,
  BoxContainer,
  UpDownVotes,
  UpDownVotesProps,
  UpDownVoteDemo,
  UpDownVoteArea,
} from 'Components';

export interface EndorsementCardProps {
  organizationId: string;
  name: string;
  thumbnailImage: string;
  description: string;
  websiteUrl: string;
  organizationType: string;
  upDownVotes?: UpDownVotesProps;
}

const CardText = styled.div(() => {
  const { color, font } = useTheme();
  return css`
    font-family: ${font.family.proximaNova};
    font-size: 1rem;
    color: ${color.text.primary};
    text-align: center;
  `;
});

const Name = styled(CardText)`
  margin-top: 1rem;
`;

const Category = styled(CardText)(() => {
  const { color } = useTheme();
  return css`
    color: ${color.text.secondary};
    margin-bottom: 1rem;
  `;
});

export function EndorsementCard({
  name,
  thumbnailImage,
  organizationType,
  upDownVotes,
}: EndorsementCardProps): JSX.Element {
  const $upDownVoteArea = upDownVotes ? (
    <UpDownVotes {...upDownVotes} />
  ) : (
    <UpDownVoteDemo />
  );
  return (
    <BoxContainer outline padding="1.5rem 3rem" center>
      <Category>{organizationType}</Category>
      <Avatar name={name} src={thumbnailImage} />
      <Name>{name}</Name>
      <UpDownVoteArea>{$upDownVoteArea}</UpDownVoteArea>
    </BoxContainer>
  );
}
