import React from 'react';
import styled from 'styled-components';
import { FaCheckCircle, FaCircle, FaTimesCircle } from 'react-icons/fa';

import { IconStack } from 'Components';
import { Organization, palette } from 'Context';

interface ContainerProps {
  theme: any;
  empty?: boolean;
}

const Container = styled.section<ContainerProps>`
  background-color: ${({ theme }) => theme.color.background.secondary};
  padding: 0rem 1rem;
  margin: 1rem 0;
  border-radius: 2rem;
  border: 1px solid ${palette.blueDark};
  display: flex;
  justify-content: ${(props) => (props.empty ? 'center' : 'space-between')};
  align-items: center;
  @media only screen and (max-width: 1023px) {
    border-radius: 1rem;
  }
  span {
    font-weight: 300;
    font-family: ${({ theme }) => theme.font.family.commutersSans};
    font-size: 1.4rem;
    line-height: 3.5rem;
    color: ${({ theme }) => theme.color.text.secondary};
  }
`;

const OrganizationHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &&& {
    h4 {
      line-height: 1.6rem;
      text-align: left;
      margin-left: 1rem;
      font-size: 1.25rem;
    }
    img {
      border-radius: 50px;
    }
    a {
      text-decoration: none;
    }
  }
`;

export default function OrganizationCard(props: {
  organization?: Organization;
  supporting?: boolean;
  opposing?: boolean;
}): JSX.Element {
  const { organization, supporting, opposing } = props;

  if (organization == null) {
    return (
      <Container empty>
        <span>None</span>
      </Container>
    );
  }

  return (
    <Container>
      <OrganizationHeader>
        <a
          href={organization.websiteUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            alt="author-logo"
            height="50px"
            src={organization.thumbnailImage}
          ></img>
        </a>
        <h4>
          <a
            href={organization.websiteUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {organization.name}
          </a>
        </h4>
      </OrganizationHeader>
      <IconStack>
        <FaCircle color="white" size={'3rem'} />
        {supporting && (
          <FaCheckCircle color={palette.greenSupport} size={'3rem'} />
        )}
        {opposing && <FaTimesCircle color={palette.red} size={'3rem'} />}
      </IconStack>
    </Container>
  );
}
