import styled, { css } from 'styled-components';
import React from 'react';
import { palette, BallotMeasureDetail } from 'Context';
import { CompareContainer } from 'Components';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const YesNoContainer = styled.article(({ theme: { color } }) => {
  return css`
    background-color: ${color.background.secondary};
    border-radius: 2rem;
    border: 1px solid ${palette.blueDark};
    padding: 2rem;
    flex: 1 1 50%;

    @media only screen and (max-width: 1023px) {
      padding: 1.5rem 0rem 1rem;
      border-radius: 1rem;
    }

    header {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h4 {
      background-color: ${color.foreground.primary};
      font-size: 4rem;
      font-weight: 400;
      padding: 1rem 4rem;
      border-radius: 5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
    }

    button {
      width: 100%;
      max-width: 100%;
    }
  `;
});

const SupportIconContainer = styled.div`
  position: relative;
  background-color: white;
  background-position: center;
  border-radius: 100px;
  width: 4.6rem;
  height: 4.6rem;
`;

const SupportIcon = styled.div`
  margin-top: -0.2rem;
  margin-left: -0.2rem;
`;

const OpposeIconContainer = styled.div`
  position: relative;
  background-color: white;
  background-position: center;
  border-radius: 100px;
  width: 4.6rem;
  height: 4.6rem;
`;

const OpposeIcon = styled.div`
  margin-top: -0.2rem;
  margin-left: -0.2rem;
`;

export const BallotMeasureVoteMeaning = (props: {
  measure: BallotMeasureDetail;
}): JSX.Element => {
  const { measure } = props;

  return (
    <CompareContainer>
      <YesNoContainer>
        <header>
          <SupportIconContainer>
            <SupportIcon>
              <FaCheckCircle color={palette.greenSupport} size={'5rem'} />
            </SupportIcon>
          </SupportIconContainer>
        </header>

        <p>
          A <strong>YES</strong> {measure?.yesVoteMeans.slice(5)}
        </p>
      </YesNoContainer>

      <YesNoContainer>
        <header>
          <OpposeIconContainer>
            <OpposeIcon>
              <FaTimesCircle color={palette.red} size={'5rem'} />
            </OpposeIcon>
          </OpposeIconContainer>
        </header>

        <p>
          A <strong>NO</strong> {measure?.noVoteMeans.slice(4)}
        </p>
      </YesNoContainer>
    </CompareContainer>
  );
};
