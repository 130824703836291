const blueText = '#55A3BD';
const blueLight = '#8ED7EE';
const blue = '#006586';
const blueDark = '#003C55';
const blueDarker = '#002B41';
const blueDarkest = '#002135';

const red = '#D64646';
const yellow = '#FFE45C';
const green = '#8EEA78';
const greenSupport = '#46B918';

const aqua = '#6EE1BA';
const violet = '#C3B1E5';
const salmon = '#E78F84';
const orange = '#FFB45C';
const purple = '#8F77BB';

const white = '#FFFFFF';
const greyDarkest = '#333333'; // Not being used yet
const greyDarker = '#666666'; // Not being used yet
const greyDark = '#999999';
const grey = '#AAAAAA';
const greyLight = '#CCCCCC';
const greyLighter = '#DDDDDD'; // Not being used yet
const greyLightest = '#EEEEEE'; // Not being used yet
const black = '#002135';

const greyBackground = '#D6D6D6';

const greys = {
  white,
  black,
  greyDarkest,
  greyDarker,
  greyDark,
  grey,
  greyLight,
  greyLighter,
  greyLightest,
  greyBackground,
};

export const palette = {
  blue,
  blueText,
  blueDark,
  blueDarker,
  blueDarkest,
  blueLight,
  red,
  aqua,
  violet,
  yellow,
  green,
  greenSupport,
  salmon,
  orange,
  ...greys,
};

const parties = {
  republican: red,
  democratic: blue,
  democrat: blue,
  libertarian: yellow,
  independent: violet,
  green,
  Republican: red,
  Democratic: blue,
  Democrat: blue,
  Libertarian: yellow,
  Independent: violet,
  Green: green,
  other: purple,
};

type PartiesType = typeof parties;
export type Party = keyof PartiesType;

export function getPartyColor(party: Party = 'other'): string {
  return `${party}` in parties ? parties[party] : parties.other;
}

const primary = {
  ...greys,
  parties,

  error: red,
  success: green,
  info: yellow,

  palette,

  background: {
    primary: blueDarkest,
    secondary: blueDarker,
  },
  foreground: {
    primary: blue,
    secondary: white,
  },
  text: {
    primary: white,
    secondary: blueText,
  },

  button: {
    solid: {
      default: {
        text: white,
        background: blueDarkest,
        border: blue,
      },
      hover: {
        text: white,
        background: blueDarker,
        border: blue,
      },
      click: {
        text: white,
        background: blue,
        border: blue,
      },
    },
    outlined: {
      default: {
        text: white,
        background: blueDarkest,
        border: blue,
      },
      hover: {
        text: white,
        background: blueDarker,
        border: blue,
      },
      click: {
        text: white,
        background: blue,
        border: blue,
      },
    },
    inactive: {
      text: blueDark,
      background: blueDarker,
      border: blueDark,
    },
  },
};

/********** Theme Overrides **********/

const darkBlue = {
  foreground: {
    primary: blueDark,
    secondary: white,
  },
};

const ratings = {}; //Ratings uses default theme

const endorsements = {
  foreground: {
    primary: aqua,
    secondary: blueDarker,
  },
  text: {
    primary: white,
    secondary: aqua,
  },
};

const financials = {
  foreground: {
    primary: green,
    secondary: blueDarker,
  },
  text: {
    primary: white,
    secondary: green,
  },
};

const legislation = {
  foreground: {
    primary: violet,
    secondary: blueDarkest,
  },
  text: {
    primary: white,
    secondary: violet,
  },
};

const quote = {
  foreground: {
    primary: yellow,
    secondary: blueDarker,
  },
  text: {
    primary: white,
    secondary: yellow,
  },
};

const news = {
  foreground: {
    primary: salmon,
    secondary: blueDarker,
  },
  text: {
    primary: white,
    secondary: salmon,
  },
};

const greyscale = {
  background: {
    primary: greyBackground,
    secondary: greyLight,
  },
  foreground: {
    primary: blue,
    secondary: white,
  },
  text: {
    primary: blueDarkest,
    secondary: blue,
  },

  button: {
    solid: {
      default: {
        text: white,
        background: blueDarkest,
        border: blue,
      },
      hover: {
        text: white,
        background: blueDarker,
        border: blue,
      },
      click: {
        text: white,
        background: blue,
        border: blue,
      },
    },
    outlined: {
      default: {
        text: blue,
        background: greyBackground,
        border: blue,
      },
      hover: {
        text: white,
        background: greyLight,
        border: blue,
      },
      click: {
        text: white,
        background: blue,
        border: blue,
      },
    },
    inactive: {
      text: grey,
      background: greyLight,
      border: grey,
    },
  },
};

interface ColorSubTheme {
  primary: string;
  secondary: string;
}

export interface ColorTheme {
  background: ColorSubTheme;
  foreground: ColorSubTheme;
  text: ColorSubTheme;
}

/********** Theme Extraction Logic **********/

const colorThemes = {
  ratings,
  endorsements,
  financials,
  legislation,
  quote,
  news,
  form: greyscale,

  darkBlue,

  default: {},
  blue: {},
  aqua: endorsements,
  green: financials,
  violet: legislation,
  yellow: quote,
  salmon: news,
  greyscale,
};

export type ColorThemeKey = keyof typeof colorThemes;

export const getColorTheme = (key?: ColorThemeKey): typeof primary =>
  key && key in colorThemes ? { ...primary, ...colorThemes[key] } : primary;
