import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Cookies from 'js-cookie';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import { getTheme, AuthFlowContext, useAuth, AuthState } from 'Context';
import { registerUser, RegisterAttrs, loginUser, LoginAttrs } from 'Services';

import { BoxContainer, LoaderTakeover } from 'Components';
import { HIDE_GUEST } from 'Flags';

import LoginTab from './LoginTab';
import RegisterTab from './RegisterTab';
import GuestTab from './GuestTab';
import SuccessTab from './SuccessTab';

interface AuthFlowProps {
  onCancel?: () => void;
  hardRoutes?: boolean;
}

const Wrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export function AuthFlow({
  onCancel,
  hardRoutes = false,
}: AuthFlowProps): JSX.Element {
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = useState<string>();
  const { setUser, setAuthModalState, authModalState } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();

  const formActions = {
    hardRoutes,
    onCancel,
    setTab: (tab: AuthState) => {
      if (hardRoutes) {
        history.push(tab);
      } else {
        setError('');
        setAuthModalState(tab);
      }
    },
    error,
    setError,
    submitRegister: (input: RegisterAttrs) => {
      setLoading(true);
      const { email, password, zipCode = '' } = input;
      registerUser({ email, password, zipCode })
        .then((res) => {
          Cookies.set('token', res?.data?.token);
          setUser(res?.data?.user);
          setAuthModalState('success');
        })
        .catch((err) => {
          // need to standardize this and create a custom type
          let errorMessages = '';
          for (const [key, value] of Object.entries(err.response.data)) {
            errorMessages += `${key}: ${value} \n`;
          }
          setError(errorMessages);
          console.error('Registration Error', { error: err.response.data });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    submitLogin: (input: LoginAttrs) => {
      setLoading(true);
      loginUser(input)
        .then((res) => {
          Cookies.set('token', res?.data?.token, {
            expiry: res?.data?.expiry,
          });
          setUser(res?.data?.user);
          setAuthModalState(null);
        })
        .catch((err) => {
          setError(`Login Error: ${err?.message}`);
          console.error('Error logging in', { error: err });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    setGuestCookie: (zipPlusFour: string) => {
      Cookies.set('zipPlusFourTest', zipPlusFour, {
        expires: moment().add('2 hours').toDate(),
      });
    },
    onContinue: () => {
      setAuthModalState(null);
      history.push('/ballot');
    },
  };

  // If we are in the Modal, we want to check by modal state, otherwise check route
  const tabCheck = hardRoutes
    ? pathname.replace(/^\/|\/$/g, '')
    : authModalState;

  return (
    <AuthFlowContext.Provider value={formActions}>
      <ThemeProvider theme={getTheme('form')}>
        <BoxContainer width="500px" margin="auto" padding="60px 70px 50px">
          <Wrapper>
            {isLoading && <LoaderTakeover />}
            {(() => {
              switch (tabCheck) {
                case 'login':
                  return <LoginTab />;
                case 'register':
                  return <RegisterTab />;
                case 'guest':
                  return HIDE_GUEST ? <LoginTab /> : <GuestTab />;
                case 'success':
                  return <SuccessTab />;
              }
            })()}
          </Wrapper>
        </BoxContainer>
      </ThemeProvider>
    </AuthFlowContext.Provider>
  );
}

export const AuthFlowRoutes = (): JSX.Element => <AuthFlow hardRoutes />;
