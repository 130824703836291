import { Organization } from 'Context';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &&& {
    h4 {
      margin-left: 1rem;
      line-height: 1.6rem;
      text-align: left;
    }
    img {
      width: 3rem;
      border-radius: 50px;
    }
    a {
      text-decoration: none;
    }
  }
`;

export function OrganizationHeader(props: {
  organization: Organization;
}): JSX.Element {
  const { organization } = props;
  return (
    <Container>
      <a
        href={organization.websiteUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img alt="author-logo" src={organization.thumbnailImage}></img>
      </a>
      <a
        href={organization.websiteUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        <h4>{organization.name}</h4>
      </a>
    </Container>
  );
}
