import styled from 'styled-components';

export const Section = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  transform-style: preserve-3d;
  flex-shrink: 0;
`;

export const Section1 = styled(Section)`
  margin-top: 0;
  margin-bottom: 3%;
`;

export const Section1b = styled(Section)`
  align-items: flex-start;
  margin-bottom: 7%;

  #s1bcol1 {
    width: 50%;
    max-width: 750px;
    background-color: #002a44;
    box-shadow: 7px 7px 30px 0 rgba(0, 0, 0, 0.2);
    border-radius: 40px;
  }
  #s1bcol2 {
    width: 28%;
    max-height: 200px;
    min-width: 300px;
    margin-top: -4%;
    transform: translateZ(0.5px) scale(0.9375) translateY(0px) translateX(-70px);
    box-shadow: 10px 10px 40px 0 rgba(0, 0, 0, 0.3);
  }

  #s1bcol2 img {
    border-radius: 40px;
  }
`;

export const Section2 = styled(Section)`
  align-items: center;
  margin-bottom: 5%;

  #s2col1 {
    width: 50%;
    transform: translateZ(1px) scale(0.925) translateY(0px) translateX(40px);
    box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.3);
    border-radius: 40px;

    img {
      width: 100%;
      vertical-align: middle;
      border-radius: 0px 40px 40px 0px;
    }
  }

  #s2col2 {
    width: 50%;
    background-color: #002a44;
    box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 40px 0px 0px 40px;
    margin-left: -100px;
    padding-left: 100px;
  }
`;

export const Section3 = styled(Section)`
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2%;

  #s3col1 {
    width: 50%;
    /*min-width: 500px;*/
    background-color: #002a44;
    box-shadow: 7px 7px 30px 0 rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    margin-right: -100px;
    padding-right: 100px;
  }

  #s3col2 {
    width: 25%;
    min-width: 300px;
    transform: translateZ(2px) scale(0.75) translateY(-250px) translateX(-170px);
    box-shadow: 10px 10px 40px 0 rgba(0, 0, 0, 0.3);
    border-radius: 40px;
    img {
      width: 100%;
      /*max-width: 300px;*/
      height: auto;
      vertical-align: middle;
      border-radius: 40px;
    }
  }
`;

export const Section4 = styled(Section)`
  justify-content: center;
  margin-top: -13%;
  color: #002135;
  z-index: -1;
  transform-style: inherit;
  flex-flow: row wrap;

  #s4row1 {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
  }

  #s4row2 {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #002a44;
    color: white;
  }

  img {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 30px;
  }
`;
