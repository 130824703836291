/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import axios, { Method } from 'axios';
import Cookies from 'js-cookie';

interface RequestParams {
  data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  method?: Method;
  token?: string;
}

export async function request<T>(url = '', params: RequestParams = {}) {
  const { data = {}, method, token } = params;

  const res = await axios.request<T>({
    url,
    method,
    data,
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  });
  return res;
}

export const post = <T>(url = '', data = {}) =>
  request<T>(url, { data, method: 'POST' });

export const requestWithToken = <T>(url = '', params: RequestParams = {}) => {
  const { data = {}, method = 'GET', token = Cookies.get('token') } = params;
  return token
    ? request<T>(url, { data, method, token })
    : Promise.resolve<any>({}); // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const postWithToken = <T>(url = '', data = {}) =>
  requestWithToken<T>(url, { data, method: 'POST' });
