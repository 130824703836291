import React from 'react';
import { ScrollInner, ScrollOuter } from './shared';
import { FaArrowUp } from 'react-icons/fa';
import { scrollToTop } from 'Utils';

export function ScrollToTopButton(): JSX.Element {
  return (
    <ScrollOuter>
      <ScrollInner onClick={() => scrollToTop('smooth')}>
        <FaArrowUp size={20} />
      </ScrollInner>
    </ScrollOuter>
  );
}
