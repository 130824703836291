import React from 'react';
import styled from 'styled-components';
import { SCheckbox } from 'Components';
import { FieldError } from 'react-hook-form';

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  color: ${({ theme: { color } }) => color.text.primary};
`;

export interface CheckboxProps {
  name?: string;
  label?: string;
  error?: FieldError;
  checked?: boolean;
  onChange?: (e?: React.FormEvent<HTMLInputElement>) => void;
}

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
export const Checkbox = React.forwardRef((props: CheckboxProps, ref: any) => {
  const { name = '', label = '', checked = false, error, onChange } = props;
  return (
    <Label htmlFor={name}>
      <SCheckbox
        ref={ref}
        name={name}
        checked={checked}
        error={!!error}
        onChange={onChange}
      />
      {label}
    </Label>
  );
});
