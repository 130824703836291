import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  width: 100vw;
  display: grid;
  grid-template-areas:
    'mainNav'
    'content'
    'contextNav';
  @media only screen and (min-width: 1024px) {
    grid-template-areas:
      '     contextNav'
      '     content';
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface AppLayoutProps {
  children: ReactNode;
}

export default function AppLayout({ children }: AppLayoutProps): JSX.Element {
  return <Layout>{children}</Layout>;
}
