import React from 'react';
import styled, { css } from 'styled-components';
import { GapContainer } from 'Components';

export const IssuePill = styled.span(({ theme: { color, font } }) => {
  return css`
    border: 1px solid ${color.foreground.primary};
    box-sizing: border-box;
    border-radius: 40px;

    padding: 10px;
    font-family: ${font.family.commutersSans};
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */

    color: ${color.text.primary};
  `;
});

export interface IssuesProps {
  title: string;
  issues: { name: string; id: string }[];
}

export function Issues({ title, issues }: IssuesProps): JSX.Element {
  return (
    <GapContainer
      wrap
      gap="10px"
      style={{
        margin: 'auto 10px',
        justifyContent: 'center',
      }}
    >
      <IssuePill style={{ border: 'none' }}>{title}:</IssuePill>
      {issues.map((issue) => (
        <IssuePill key={issue.id}>{issue.name}</IssuePill>
      ))}
    </GapContainer>
  );
}
