import React from 'react';
import styled from 'styled-components';
import { loaderBlue } from 'Images';

const FlagGif = styled.img.attrs((props) => ({
  src: loaderBlue,
  alt: '🇺🇸 Loading',
  height: props.height ?? 'auto',
}))``;

export function FlagLoader({ height }: { height?: string }): JSX.Element {
  return <FlagGif height={height} />;
}
