import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';

export function Draggable({
  children,
  handleDrag,
}: {
  children: JSX.Element;
  handleDrag: (mx: number, my: number) => void;
}): JSX.Element {
  const [{ x, y }, set] = useSpring(() => ({ x: 0, y: 0 }));

  // Set the drag hook and define component movement based on gesture data
  const bind = useDrag(({ down, movement: [mx, my] }) => {
    // up and down gesture can also be set by adding `y: down ? my : 0` to the setter below
    // without this, the child component will not move along the y axis
    set({ x: down ? mx : 0 });
    handleDrag(mx, my);
  });

  console.log('draggable', { x, y });

  // Bind it to a component
  return (
    <animated.div {...bind()} style={{ x, y }}>
      {children}
    </animated.div>
  );
}
