import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthFlowActions } from 'Context';
import { RegisterAttrs } from 'Services';
import {
  SHeading,
  SSubHeading,
  Txt,
  SLink,
  SButton,
  SSubmit,
  SDivider,
  emailExp,
  TextInput,
  ErrorText,
  GapContainer,
  DataListInput,
} from 'Components';

import { HIDE_ADDRESS } from 'Flags';

import { useAddressAutocomplete, useAddressData } from 'Hooks';

export default function Register(): JSX.Element {
  const { setTab, onCancel, submitRegister, error } = useAuthFlowActions();
  const [addressInputValue, setAddressInputValue] = useState('');
  const [verifiedAddress, setVerifiedAddress] = useState(null);
  const [zip, setZip] = useState<string>();

  const { handleSubmit, formState, register } = useForm<RegisterAttrs>({
    mode: 'onChange',
  });

  const { errors, isValid, isDirty } = formState;

  const suggestions = useAddressAutocomplete(addressInputValue);

  const addressData = useAddressData(verifiedAddress);

  const handleAddress = (e?: React.FormEvent<HTMLInputElement>) => {
    setAddressInputValue(e?.currentTarget.value ?? '');
    if (suggestions?.map((s) => s.label).includes(e?.currentTarget.value)) {
      setVerifiedAddress(suggestions[0]);
    }
  };

  useEffect(() => {
    if (addressData) {
      const { zipcode, plus4_code: plus4code } = addressData[0].components;
      const newZip = zipcode + '-' + plus4code;
      setZip(newZip);
    }
  }, [addressData]);

  return (
    <form onSubmit={handleSubmit(submitRegister)}>
      <SHeading>Create an account</SHeading>
      <SDivider />

      {!HIDE_ADDRESS && (
        <>
          <Txt>
            We need your address so we can serve you accurate election ballot
            information and local news.
          </Txt>
          <SDivider />
          <DataListInput
            items={suggestions}
            label="Address"
            value={addressInputValue}
            onChange={handleAddress}
            showError={!!error && addressInputValue.length < 1}
          />
          <input type="hidden" value={zip} {...register('zipCode')} />
          <SDivider />
        </>
      )}

      <GapContainer direction="row" fullWidth>
        <TextInput
          {...register('firstName', { required: true })}
          error={errors.firstName}
          label="First Name"
        />
        <TextInput
          {...register('lastName', { required: true })}
          error={errors.lastName}
          label="Last Name"
        />
      </GapContainer>

      <TextInput
        {...register('email', { required: true })}
        error={errors.email}
        type="email"
        label="Email"
        pattern={emailExp}
      />

      <TextInput
        {...register('password', {
          required: true,
          minLength: { value: 8, message: '8 characters minimum' },
        })}
        type="password"
        error={errors.password}
        label="Password"
      />

      <SSubmit
        readOnly
        value="Create Account"
        fullWidth
        disabled={!isDirty || !isValid}
      />

      {onCancel && (
        <SButton onClick={onCancel} fullWidth>
          Cancel
        </SButton>
      )}

      {error && <ErrorText>{error}</ErrorText>}

      <SDivider />

      <SSubHeading>
        We will absolutely never share your information.
      </SSubHeading>

      <SLink onClick={() => alert('Privacy Policy!')}>Privacy Policy</SLink>

      <br />

      <SLink onClick={() => setTab('login')}>
        Already Have an account? Sign In
      </SLink>
    </form>
  );
}
