import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

import { UserMenu, NavItems, Avatar } from 'Components';
import { palette, useAuth } from 'Context';
import { useScrollPosition } from 'Hooks';

import { HIDE_AUTH } from 'Flags';

import { logoMark, logoPNG as logoHeader, chevron, linkExternal } from 'Images';

interface WrapperProps {
  sticky?: boolean;
  isHidden?: boolean;
}

const Wrapper = styled.nav<WrapperProps>`
  grid-area: mainNav;
  visibility: visible;

  @media only screen and (max-width: 1023px) {
    position: sticky;
    transform: ${(props) =>
      props.sticky ? 'translateY(0%)' : 'translateY(-100%)'};
    transition: transform 400ms ease-in;
    top: 0;
    z-index: 10;
    padding: 0 1rem;
    background: ${({ theme: { color } }) => color.background.primary};
    box-shadow: ${({ theme: { color } }) =>
      '0px 7px 9px 0px ' + color.background.primary};
    background: ${palette.blueDarker};
  }
  @media only screen and (min-width: 1024px) {
    display: none;
    max-width: 0;
    background: ${({ theme: { color } }) => color.background.secondary};
    visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  }
  a {
    text-decoration: none;
  }
`;

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MobileNavContent = styled.section(({ theme: { color, font } }) => {
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0 1rem 0;
    flex-wrap: wrap;
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    @media only screen and (min-width: 1024px) {
      display: none;
    }
    svg {
      cursor: pointer;
      color: ${palette.blue};
      font-size: 1.5rem;
    }
    #subtitle {
      flex-basis: 100%;
      margin-top: 10px;
      text-align: center;
      font-family: ${font.family.commutersSans};
      font-size: 0.9rem;
    }
  `;
});

const DesktopNavContent = styled.div`
  @media only screen and (max-width: 1023px) {
    display: none;
  }
  max-width: 180px;
  height: 100%;
`;

// interface NavItemProps {
//   selected: boolean;
// }

// const NavItem = styled.div<NavItemProps>(
//   ({ theme: { color, font }, selected }) => {
//     return css`
//       font-family: ${font.family.commutersSans};
//       text-align: center;
//       padding: 1rem 2rem;
//       font-size: 1.25rem;
//       background: ${selected ? color.foreground.primary : ''};
//       &:hover {
//         color: ${selected ? '' : palette.salmon};
//       }
//     `;
//   }
// );

// --------------- BACK BUTTON IN MOBILE HEADER

const ChevronLeft = styled.img.attrs(() => ({
  src: chevron,
  alt: '<',
}))({ height: 24 });

const BackButton = styled.div`
  position: relative;
`;

// ---------------------------------------------

const LogoContainer = styled.div`
  text-align: center;
  padding: 2rem 1rem;
`;

const Logo = styled.img.attrs(() => ({
  src: logoMark,
  alt: '🇺🇸',
  width: '100px',
}))``;

const LogoHeader = styled.img.attrs(() => ({
  src: logoHeader,
  alt: '🇺🇸 Populist',
  width: '40%',
}))``;

export function MainNav(): JSX.Element {
  const { pathname } = useLocation();
  const history = useHistory();

  const [sticky, setSticky] = useState<boolean>(true);

  useScrollPosition(
    ({
      prevPos,
      currPos,
    }: {
      prevPos: { y: number };
      currPos: { y: number };
    }) => {
      // hack because safari thinks its cool to have a bouncy effect and allow scroll position to exceed 0
      let prevPosY = prevPos.y;
      if (prevPosY > 0) {
        prevPosY = 0;
      }
      const isShow = currPos.y >= prevPosY;
      if (isShow !== sticky) setSticky(isShow);
    },
    [sticky]
  );

  return (
    <Wrapper
      sticky={sticky}
      isHidden={
        pathname === '/404' ||
        pathname === '/login' ||
        pathname === '/register' ||
        pathname === '/guest'
      }
    >
      <MobileNavContent onClick={() => history.push('/colorado')}>
        <div style={{ width: '1rem' }}>
          {pathname.includes('/colorado/') ? (
            <BackButton>
              <ChevronLeft
                color={palette.white}
                onClick={() => history.push('/colorado')}
                style={{ cursor: 'pointer' }}
              />
            </BackButton>
          ) : (
            <a href="https://www.populist.us/ballot">
              <FaHome />
            </a>
          )}
        </div>
        <LogoHeader />
        <a href="https://www.populist.us/register">
          <img src={linkExternal} alt="copy" />
        </a>
        <div id="subtitle">Colorado 2022 Ballot Measures</div>
      </MobileNavContent>
    </Wrapper>
  );
}
