import React from 'react';
import styled, { useTheme, css } from 'styled-components';
import moment from 'moment';

import { Row } from 'Components';
import { SmallTitle } from '.';

export interface BasicInfoProps {
  assumedOffice?: string;
  termEnds?: string;
  electionsWon: number;
  electionsLost: number;
  yearsInOffice: number;
}

const T1 = styled.span`
  font-size: 1.25rem;
`;

const T2 = styled.span(() => {
  const { color } = useTheme();

  return css`
    font-size: 1.25rem;
    color: ${color.text.secondary};
  `;
});

const Wrapper = styled.div`
  padding: 0 2rem;
`;

export function BasicInfo(props: BasicInfoProps): JSX.Element {
  const {
    assumedOffice,
    termEnds,
    electionsWon,
    electionsLost,
    yearsInOffice,
  } = props;

  return (
    <Wrapper>
      <SmallTitle center>Basic Info</SmallTitle>
      {assumedOffice && (
        <Row margin=".5rem 0">
          <T1>Assumed Office</T1>
          <T2>{moment(assumedOffice).format('YYYY')}</T2>
        </Row>
      )}
      {termEnds && (
        <Row margin=".5rem 0">
          <T1>Term Ends</T1>
          <T2>{moment(termEnds).format('YYYY')}</T2>
        </Row>
      )}
      <Row margin=".5rem 0">
        <T1>Elections Won / Lost</T1>
        <T2>
          {electionsWon} / {electionsLost}
        </T2>
      </Row>
      <Row margin=".5rem 0">
        <T1>Years In Public Office</T1>
        <T2>{+yearsInOffice}</T2>
      </Row>
    </Wrapper>
  );
}
