import React from 'react';
import styled, { css } from 'styled-components';
import { palette } from 'Context';

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Chip = styled.div(({ theme: { color } }) => {
  return css`
    padding: 0.6rem 1.5rem;
    background: ${palette.blueDark};
    color: ${color.foreground.secondary};
    border-radius: 1.6rem;
    margin: 0.6rem;
    font-size: 1.2rem;
    text-transform: capitalize;
    :last-child {
      margin-right: 0;
    }
  `;
});

export function ChipsContainer(props: { chips: string[] }): JSX.Element {
  const { chips } = props;
  if (chips)
    return (
      <Container>
        {chips
          .filter((chip) => chip.length > 0)
          .map((text: string) => (
            <Chip key={Math.random()}>{text}</Chip>
          ))}
      </Container>
    );
  return <div />;
}
