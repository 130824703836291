import React, { useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'Hooks';
import { PasswordParams, useAuth } from 'Context';
import { RegisterAttrs, updatePassword, updateUser } from 'Services';

import {
  DesktopContainer,
  MobileContainer,
  FlagSection,
  FlagLoader,
} from 'Components';

import { PersonalInfo, ChangePassword, DestroyAccount } from './shared';

const Wrapper = styled.div`
  overflow: auto;
  padding: 1rem 2rem;
`;

export default function UserProfilePage(): JSX.Element {
  const { user, setUser } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleSave = (userData: RegisterAttrs) => {
    setLoading(true);
    updateUser(userData)
      .then((res) => {
        setUser(res.data);
      })
      .finally(() => setTimeout(() => setLoading(false), 1500));
  };

  const handlePasswordSave = (passwordData: PasswordParams) => {
    setLoading(true);
    updatePassword(passwordData)
      .then(() => alert('Your password has been changed successfully!'))
      .finally(() => setTimeout(() => setLoading(false), 1500));
  };

  const flexDirection = useMediaQuery(
    ['(max-width: 1023px)', '(min-width: 1024px)'],
    ['column', 'row'],
    'column'
  );

  if (!user) {
    return <FlagLoader />;
  }

  const $content = (
    <>
      <PersonalInfo
        flexDirection={flexDirection}
        loading={loading}
        handleSave={handleSave}
      />
      <ChangePassword handlePasswordSave={handlePasswordSave} />{' '}
      <DestroyAccount />
    </>
  );

  return (
    <>
      <DesktopContainer>
        <Wrapper>
          <FlagSection colorTheme="darkBlue" title="Edit Profile">
            {$content}
          </FlagSection>
        </Wrapper>
      </DesktopContainer>
      <MobileContainer>{$content}</MobileContainer>
    </>
  );
}
