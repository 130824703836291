import React from 'react';
import { OutlineContainer } from 'Components';
import styled, { css } from 'styled-components';

const Title = styled.span(({ theme }) => {
  const { font, color } = theme;
  return css`
    font-family: ${font.family.commutersSans};
    font-size: 12px;
    line-height: 15px;
    position: absolute;
    text-transform: uppercase;
    top: -6px;
    left: 50px;
    background: ${color.background.secondary};
    padding: 0 5px;
    margin: 0;
  `;
});

const Wrapper = styled.div`
  position: relative;
`;

export interface OutlineSectionProps {
  title?: string;
  borderColor?: string;
  children: React.ReactNode;
}

export function OutlineSection(props: OutlineSectionProps): JSX.Element {
  const { title, borderColor, children } = props;
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <OutlineContainer
        borderColor={borderColor}
        flexDirection="row"
        padding="0"
      >
        {children}
      </OutlineContainer>
    </Wrapper>
  );
}
