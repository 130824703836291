import { useEffect, useState, useCallback } from 'react';
import { PaginationProps } from 'Components';
import { shuffle, range } from 'Utils';

interface PaginationReturn<T> extends PaginationProps {
  currentData: () => T[];
  prevData: () => T[] | null;
  nextData: () => T[] | null;
  showAll: () => void;
  repaginate: () => void;
  startIndex: number;
  endIndex: number;
  sortRandom: () => void;
  sortData: (compareFn: (a: T, b: T) => number) => void;
  items: (number | string)[];
  debug?: boolean;
}

export function usePagination<T>(
  initialData: Array<T>,
  pageSize: number,
  siblingCount = 5,
  boundaryCount = 1,
  debug = false
): PaginationReturn<T> {
  const [data, setData] = useState<T[]>(initialData);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(pageSize);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const [items, setItems] = useState<(number | string)[]>([]);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  if (debug) console.log({ currentPage });

  const nextPageDefault = useCallback(() => {
    if (debug) console.log('next page called');
    setCurrentPage((cp) => Math.min(cp + 1, pageCount));
  }, [setCurrentPage, pageCount, debug]);

  const prevPageDefault = useCallback(() => {
    if (debug) console.log('prev page called');
    setCurrentPage((cp) => Math.max(cp - 1, 1));
  }, [setCurrentPage, debug]);

  const nextPage = nextPageDefault;
  const prevPage = prevPageDefault;

  const currentData = (): Array<T> => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  };

  const prevData = (): T[] | null => {
    if (currentPage === 1) return null;
    const start = (currentPage - 2) * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  };

  const nextData = (): T[] | null => {
    if (currentPage === pageCount) return null;
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  };

  const showAll = () => {
    setCurrentPage(1);
    setItemsPerPage(data.length);
  };

  const repaginate = () => setItemsPerPage(pageSize);

  const startIndex = (currentPage - 1) * itemsPerPage + 1;

  const endIndex = Math.min(startIndex + itemsPerPage - 1, data.length);

  /// numbers mode:

  const getItems = useCallback(() => {
    const startPages = range(1, Math.min(boundaryCount, pageCount));
    const endPages = range(
      Math.max(pageCount - boundaryCount + 1, boundaryCount + 1),
      pageCount
    );

    const siblingsStart = Math.max(
      Math.min(
        // Natural start
        currentPage - siblingCount,
        // Lower boundary when page is high
        pageCount - boundaryCount - siblingCount * 2 - 1
      ),
      // Greater than startPages
      boundaryCount + 2
    );

    const siblingsEnd = Math.min(
      Math.max(
        // Natural end
        currentPage + siblingCount,
        // Upper boundary when page is low
        boundaryCount + siblingCount * 2 + 2
      ),
      // Less than endPages
      endPages.length > 0 ? endPages[0] - 2 : pageCount - 1
    );
    return [
      ...startPages,

      ...(siblingsStart > boundaryCount + 2
        ? ['...']
        : boundaryCount + 1 < pageCount - boundaryCount
        ? [boundaryCount + 1]
        : []),

      ...range(siblingsStart, siblingsEnd), // Sibling pages

      ...(siblingsEnd < pageCount - boundaryCount - 1
        ? ['...']
        : pageCount - boundaryCount > boundaryCount
        ? [pageCount - boundaryCount]
        : []),

      ...endPages,
    ];
  }, [boundaryCount, currentPage, pageCount, siblingCount]);

  useEffect(() => setItems(getItems()), [getItems]);

  // sorting

  const sortRandom = () => {
    setData(shuffle(data));
    setItems(getItems());
  };

  const sortData = (compareFn: (a: T, b: T) => number) => {
    setData(data.sort(compareFn));
    setItems(getItems());
  };

  return {
    nextPage,
    prevPage,
    currentData,
    prevData,
    nextData,
    currentPage,
    setCurrentPage,
    showAll,
    repaginate,
    pageCount,
    startIndex,
    endIndex,
    sortRandom,
    sortData,
    items,
  };
}
