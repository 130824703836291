import React from 'react';
import styled, { ThemeProvider, css } from 'styled-components';
import { getTheme, ColorThemeKey } from 'Context';

const Wrapper = styled.div`
  border-left: 1px solid ${({ theme: { color } }) => color.foreground.primary};
  margin-bottom: 3rem;
  position: relative;
`;

const Content = styled.div`
  padding: 40px;

  h1 {
    margin: 0;
  }
`;

export const SectionTitle = styled.span(({ theme: { color, font } }) => {
  return css`
    font-family: ${font.family.commutersSans};
    background: ${color.foreground.primary};
    color: ${color.foreground.secondary};
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    border-radius: 0px 40px 40px 0px;
    padding: 0px 40px;
    text-transform: uppercase;
  `;
});

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
`;

interface FlagSectionProps {
  title: string;
  children: React.ReactNode;
  colorTheme?: ColorThemeKey;
  header?: React.ReactNode;
}

export function FlagSection(props: FlagSectionProps): JSX.Element {
  const { title, children, header, colorTheme } = props;
  return (
    <ThemeProvider theme={getTheme(colorTheme)}>
      <Wrapper>
        <HeaderWrapper>
          <SectionTitle>{title}</SectionTitle>
          {header}
        </HeaderWrapper>

        <Content>{children}</Content>
      </Wrapper>
    </ThemeProvider>
  );
}
