import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Markdown from 'react-markdown';
import { FaCheckCircle, FaCircle, FaTimesCircle } from 'react-icons/fa';

import {
  SButton,
  CompareContainer,
  HeaderFooterBox,
  MobileContainer,
  DesktopContainer,
  MarkdownContainer,
  IconStack,
  Draggable,
  OrganizationHeader,
  MobileTabSwitcher,
  PageNavigation,
  PageIndex,
} from 'Components';

import { BallotMeasureDetail, palette } from 'Context';

import { scrollToRef } from 'Utils';

import { usePagination } from 'Hooks/usePagination';

import { useDidMountEffect } from 'Hooks/useDidMountEffect';

const ArgumentsContainer = styled.article(({ theme: { color } }) => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 50%;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;

      h4 {
        margin: 0;
      }
      h3 {
        font-size: 1.5em;
        text-transform: uppercase;
        margin: 0 0 2rem;

        span {
          background-color: ${color.background.secondary};
          font-family: 'Proxima Nova';
          font-size: 1em;
          border-radius: 2em;
          max-height: 1em;
          margin-left: 1rem;
          text-align: center;
          padding: 0.5rem 1rem 0.4rem;
          vertical-align: middle;
        }
      }
    }
  `;
});

const MobileArguments = (props: {
  measure: BallotMeasureDetail;
}): JSX.Element => {
  const { measure } = props;

  const [argumentsSelected, setArgumentsSelected] = useState('SUPPORTING');
  const [visibleArguments, setVisibleArguments] = useState(
    measure?.forArguments
  );

  const {
    nextPage,
    prevPage,
    setCurrentPage,
    currentPage,
    pageCount,
    currentData,
  } = usePagination(visibleArguments ?? [], 1);

  const handleTabClick = (selected: string) => {
    setArgumentsSelected(selected);
    setCurrentPage(1);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDrag = (mx: number, my: number) => {
    if (mx < -100) {
      nextPage();
    }
    if (mx > 100) {
      prevPage();
    }
  };

  useEffect(() => {
    if (argumentsSelected === 'SUPPORTING') {
      setVisibleArguments(measure?.forArguments);
    }
    if (argumentsSelected === 'OPPOSING') {
      setVisibleArguments(measure?.againstArguments);
    }
  }, [argumentsSelected]);

  console.log(visibleArguments);

  return (
    <>
      <MobileTabSwitcher
        tabs={[
          {
            label: 'supporting',
            count: measure?.forArguments.length,
            selected: argumentsSelected === 'SUPPORTING',
            color: palette.greenSupport,
          },
          {
            label: 'opposing',
            count: measure?.againstArguments.length,
            selected: argumentsSelected === 'OPPOSING',
            color: palette.red,
          },
        ]}
        handleTabSelect={handleTabClick}
      />
      <ArgumentsContainer>
        <PageIndex
          items={currentData()}
          mode="dots"
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <br />
        {currentData().map((arg) => (
          <Draggable key={Math.random()} handleDrag={handleDrag}>
            <HeaderFooterBox key={Math.random()}>
              <header>
                {arg.author && <OrganizationHeader organization={arg.author} />}
                {argumentsSelected === 'SUPPORTING' && (
                  <IconStack>
                    <FaCircle color="white" size={'3rem'} />
                    <FaCheckCircle color={palette.greenSupport} size={'3rem'} />
                  </IconStack>
                )}
                {argumentsSelected === 'OPPOSING' && (
                  <IconStack>
                    <FaCircle color="white" size={'3rem'} />
                    <FaTimesCircle color={palette.red} size={'3rem'} />
                  </IconStack>
                )}
              </header>
              <MarkdownContainer>
                <Markdown>{arg.text}</Markdown>
              </MarkdownContainer>
            </HeaderFooterBox>
          </Draggable>
        ))}
        <PageNavigation prevPage={prevPage} nextPage={nextPage} />
      </ArgumentsContainer>
    </>
  );
};

export const BallotMeasureArguments = (props: {
  measure: BallotMeasureDetail;
  scrollRef?: any;
}): JSX.Element => {
  const { measure, scrollRef } = props;

  const [visibleCards, setVisibleCards] = useState(3);

  const maxCardLength = measure
    ? Math.max(measure?.forArguments.length, measure?.againstArguments.length)
    : 0;

  const [scrollPos, setScrollPos] = useState<any>();

  const detailPage: HTMLElement = document.getElementById('detail-page')!;

  const handleScroll = () => {
    if (detailPage.scrollTop !== 0) {
      setScrollPos(detailPage.scrollTop);
    }
  };

  useEffect(() => {
    if (detailPage) detailPage.addEventListener('scroll', handleScroll);
    if (detailPage)
      return () => detailPage.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailPage]);

  useDidMountEffect(() => {
    if (visibleCards !== 3) {
      detailPage.scrollTo(0, scrollPos);
    } else {
      scrollToRef(scrollRef, 'smooth');
    }
  }, [visibleCards]);

  const handleShowMoreLess = () => {
    if (visibleCards < maxCardLength) {
      setVisibleCards((prevState) => prevState + 3);
    } else {
      setVisibleCards(3);
    }
  };

  console.log(measure);

  return (
    <>
      <MobileContainer>
        <MobileArguments measure={measure} />
      </MobileContainer>
      <DesktopContainer>
        <CompareContainer>
          <ArgumentsContainer>
            <header>
              <h3>
                Supporting <span>{measure.forArguments.length}</span>
              </h3>
            </header>
            {measure.forArguments.slice(0, visibleCards).map((arg) => (
              <HeaderFooterBox key={Math.random()}>
                <header>
                  {arg.author && (
                    <OrganizationHeader organization={arg.author} />
                  )}
                  <IconStack>
                    <FaCircle color="white" size={'3rem'} />
                    <FaCheckCircle color={palette.greenSupport} size={'3rem'} />
                  </IconStack>
                </header>
                <MarkdownContainer>
                  <Markdown>{arg.text}</Markdown>
                </MarkdownContainer>
              </HeaderFooterBox>
            ))}
          </ArgumentsContainer>

          <ArgumentsContainer>
            <header>
              <h3>
                Opposing <span>{measure.againstArguments.length}</span>
              </h3>
            </header>
            {measure.againstArguments.slice(0, visibleCards).map((arg) => (
              <HeaderFooterBox key={Math.random()}>
                <header>
                  {arg.author && (
                    <OrganizationHeader organization={arg.author} />
                  )}
                  <IconStack>
                    <FaCircle color="white" size={'3rem'} />
                    <FaTimesCircle color={palette.red} size={'3rem'} />
                  </IconStack>
                </header>
                <MarkdownContainer>
                  <Markdown>{arg.text}</Markdown>
                </MarkdownContainer>
              </HeaderFooterBox>
            ))}
          </ArgumentsContainer>
        </CompareContainer>
        {maxCardLength > 3 && (
          <footer>
            <SButton outlined onClick={() => handleShowMoreLess()}>
              View {visibleCards >= maxCardLength ? 'Less' : 'More'}
            </SButton>
          </footer>
        )}
      </DesktopContainer>
    </>
  );
};
