import React from 'react';
import styled from 'styled-components';
import { SSubHeading, STextInput, SNote } from 'Components';
import { FieldError } from 'react-hook-form';

export interface TextInputProps {
  name?: string;
  label?: string;
  note?: string;
  placeholder?: string;
  type?: 'text' | 'password' | 'email';
  error?: FieldError;
  showError?: boolean;
  pattern?: RegExp;
  maxLength?: number;
  onChange?: (e?: React.FormEvent<HTMLInputElement>) => void;
  value?: string;
  defaultValue?: string;
  list?: string;
  readOnly?: boolean;
}

const InputTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding-top: 10px;
  padding-bottom: 7px;
  margin-bottom: 5px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
`;

const ReadOnlyInput = styled.input`
  border: none;
  background: transparent;
  outline: none;
  font-size: 1.2em;
  color: ${({ theme: { color } }) => color.text.secondary};
`;

function getErrorMessage(error: FieldError) {
  if (error?.message) return error.message;
  switch (error.type) {
    case 'required':
      return 'Required';
    case 'pattern':
      return 'Incorrect Pattern';
    case 'minLength':
      return 'Less than char minimum';
    default:
      return 'Field Error';
  }
}

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
export const TextInput = React.forwardRef((props: TextInputProps, ref: any) => {
  const {
    label,
    note,
    placeholder,
    name,
    type,
    error,
    onChange,
    value,
    defaultValue,
    list,
    readOnly,
  } = props;

  const showError = props.showError || !!error;

  return (
    <InputWrapper>
      <InputTopWrapper>
        {label && <SSubHeading>{label}</SSubHeading>}
        <SNote error={showError}>{error ? getErrorMessage(error) : note}</SNote>
      </InputTopWrapper>

      {readOnly && <ReadOnlyInput value={value} defaultValue={defaultValue} />}

      {!readOnly && (
        <STextInput
          type={type || 'text'}
          name={name || ''}
          placeholder={placeholder}
          ref={ref}
          error={showError}
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
          list={list}
        />
      )}
    </InputWrapper>
  );
});
