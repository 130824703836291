import React from 'react';
import styled, { css } from 'styled-components';
import { Em } from 'Components';
import { logoSVG as populistLogo } from 'Images';
import { useAuth } from 'Context';

const Wrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AuthLink = styled.div(({ theme: { font } }) => {
  return css`
    font-family: ${font.family.commutersSans};
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */
    cursor: pointer;
    color: white;
  `;
});

const Logo = styled.img.attrs(() => ({
  src: populistLogo,
  alt: '🇺🇸Populist',
}))({ height: 40 });

export function Header(): JSX.Element {
  const { setAuthModalState, user, logout } = useAuth();

  const loggedIn = !!user;

  return (
    <Wrapper>
      <Logo />
      {loggedIn ? (
        <AuthLink onClick={logout}>Log Out</AuthLink>
      ) : (
        <AuthLink onClick={() => setAuthModalState('login')}>
          Login <Em>/</Em> Sign up
        </AuthLink>
      )}
    </Wrapper>
  );
}
