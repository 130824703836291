import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { BoxContainer, GapContainer, EmptyContainer, Row } from 'Components';
import { theme } from 'Context';

const Title = styled.span(({ theme }) => {
  const { color, font } = theme;
  return css`
    color: white;
    font-family: ${font.family.commutersSans};
    font-size: 24px;
    line-height: 30px;
    border-bottom: 1px dashed ${color.foreground.primary};
  `;
});

const SubTitle = styled.span(({ theme }) => {
  const { font } = theme;
  return css`
    color: white;
    font-family: ${font.family.commutersSans};
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
  `;
});

export interface BallotSectionProps {
  title: string;
  subTitle?: string;
  children: React.ReactNode;
  useContainer?: boolean;
}

export function BallotSection(props: BallotSectionProps): JSX.Element {
  const { title, subTitle, children, useContainer = true } = props;

  const Content = useContainer ? BoxContainer : EmptyContainer;

  return (
    <>
      <Row margin="0 0 20px">
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </Row>
      <ThemeProvider theme={theme}>
        <Content style={{ marginBottom: 40 }}>
          <GapContainer gap="10px" direction="row">
            {children}
          </GapContainer>
        </Content>
      </ThemeProvider>
    </>
  );
}
