import React from 'react';

import { SHeading, SButton, SDivider, Txt } from 'Components';
import { useAuthFlowActions } from 'Context';

export default function Success(): JSX.Element {
  const { onContinue } = useAuthFlowActions();
  return (
    <>
      <SHeading>Thanks for registering</SHeading>
      <Txt>
        You’ll receive an email to verify your account - please do so within one
        week or your account will automatically be deactivated.
      </Txt>
      <SDivider />
      <SButton primary onClick={onContinue} fullWidth>
        Continue
      </SButton>
    </>
  );
}
