import { useState } from 'react';
import { SmartyStreetsAddress } from 'Context/smartyStreets';
import { useDebounce } from './useDebounce';
import { useDidMountEffect } from './useDidMountEffect';
const key = process.env.REACT_APP_SMARTY_STREETS_API_KEY;

interface AddressSuggestion {
  label: string;
  street_line: string;
  city: string;
  state: string;
  zipcode: string;
}

export function useAddressAutocomplete(
  inputValue: string
): Array<AddressSuggestion | undefined> | null {
  const [suggestions, setSuggestions] = useState<SmartyStreetsAddress[] | null>(
    null
  );

  const maxResults = 6;

  const debouncedInputValue = useDebounce(inputValue, 500);

  useDidMountEffect(() => {
    const fetchAddresses = async () => {
      if (!debouncedInputValue) return;
      try {
        const url = new URL(
          `https://us-autocomplete-pro.api.smartystreets.com/lookup`
        );
        const params = {
          key,
          search: debouncedInputValue,
          ['max_results']: maxResults.toString(), // eslint-disable-line no-useless-computed-key
          license: 'us-autocomplete-pro-startup-cloud',
        };
        url.search = new URLSearchParams(params).toString();

        const response = await fetch(url.toString());
        if (response.ok) {
          const json = await response.json();
          setSuggestions(json.suggestions);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        throw new Error(error);
      }
    };
    fetchAddresses();
  }, [debouncedInputValue]);

  const suggestionsWithLabels = suggestions?.map(
    (obj: SmartyStreetsAddress) => {
      const { street_line: streetLine, city, state, zipcode } = obj;
      return { ...obj, label: `${streetLine} ${city}, ${state} ${zipcode}` };
    }
  );

  return suggestionsWithLabels ?? null;
}
