import React, { useState } from 'react';
import styled from 'styled-components';
import { palette } from 'Context/colors';
import { FaThumbsUp, FaThumbsDown, FaEllipsisH } from 'react-icons/fa';
import { DateTime } from 'luxon';

const Card = styled.div`
  width: auto;
  @media only screen and (min-width: 769px) {
    width: 60%;
  }
  border-radius: 8px;
  background: ${palette.blueDarker};
  margin: 2rem 0;

  img {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
`;

const CardDetails = styled.div`
  padding: 1rem 1rem 0rem 1rem;

  h4 {
    margin: 0;
    color: ${palette.blueText};
  }
`;

interface PillProps {
  selected: boolean;
}

const Pill = styled.div<PillProps>`
  width: fit-content;
  margin-right: 1rem;
  border-radius: 16px;
  padding: 0.5rem;
  cursor: pointer;
  border: 1px solid
    ${(props) => (props.selected ? palette.salmon : palette.blueText)};
`;

const Footer = styled.div`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }
`;

type NewsCardProps = {
  sourceName: string;
  headline?: string;
  imageURL?: string;
  date?: number;
  url?: string;
};

export default function NewsCard(props: NewsCardProps): JSX.Element {
  const { sourceName, headline, url, imageURL, date } = props;

  const mockArticleData = {
    likes: Math.floor(Math.random() * 100),
    dislikes: Math.floor(Math.random() * 100),
  };

  const [likes, setLikes] = useState(mockArticleData.likes);
  const [likeClicked, setLikeClicked] = useState(false);
  const [dislikes, setDislikes] = useState(mockArticleData.dislikes);
  const [dislikeClicked, setDislikeClicked] = useState(false);

  // these functions will need calls to the server to update like/dislike counts
  const handleLike = () => {
    if (likeClicked) {
      setLikes(likes - 1);
    } else {
      setLikes(likes + 1);
    }
    setLikeClicked(!likeClicked);
  };

  const handleDislike = () => {
    if (dislikeClicked) {
      setDislikes(dislikes - 1);
    } else {
      setDislikes(dislikes + 1);
    }
    setDislikeClicked(!dislikeClicked);
  };

  const relativeDate = (): string => {
    const relative = DateTime.local(date).toRelativeCalendar();
    return (
      (relative?.charAt(0).toUpperCase() ?? '') + (relative?.slice(1) ?? '')
    );
  };

  return (
    <Card>
      <a href={url}>
        <img src={imageURL} alt={headline} />
      </a>
      <CardDetails>
        <h4>{sourceName}</h4>
        <h3>{headline}</h3>
        <Footer>
          <div>
            <Pill selected={likeClicked} onClick={() => handleLike()}>
              <FaThumbsUp
                style={{
                  marginRight: '1rem',
                }}
              />{' '}
              {likes}
            </Pill>
            <Pill selected={dislikeClicked} onClick={() => handleDislike()}>
              <FaThumbsDown
                style={{
                  marginRight: '1rem',
                }}
              />
              {dislikes}
            </Pill>
          </div>
          <div>
            <h5>{relativeDate()}</h5>
            <FaEllipsisH
              color={palette.blue}
              size="1.5em"
              style={{ marginLeft: '1rem' }}
            />
          </div>
        </Footer>
      </CardDetails>
    </Card>
  );
}
