import React, { Dispatch, SetStateAction } from 'react';
import { FaCircle } from 'react-icons/fa';
import styled, { css, useTheme } from 'styled-components';

import { palette } from 'Context';

// TODO: Modify mobile ballot experiences to accomodate for the margins removed below
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 0.5rem; */
  /* margin-bottom: 1.5rem; */

  svg {
    margin: 0 0.5rem;
    cursor: pointer;
  }
`;
interface LinkProps {
  isCurrentPage: boolean;
  disabled?: boolean;
}
export const Link = styled.a<LinkProps>(
  ({ isCurrentPage, disabled = false }) => {
    const { color, font } = useTheme();

    const { primary } = color.text;
    const { blueDark } = color.palette;

    return css`
      font-family: ${font.family.proximaNova};
      font-size: 1rem;
      line-height: 1rem;
      color: ${disabled ? blueDark : isCurrentPage ? primary : blueDark};
      cursor: ${isCurrentPage || disabled ? 'default' : 'pointer'};
      :hover {
        color: ${disabled ? blueDark : primary};
      }
    `;
  }
);

export const LinkText = styled.span`
  margin: 0 0.5rem;
`;

export interface PageIndexProps {
  pageCount: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  items: (number | string)[];
  mode: 'dots' | 'numbers';
  siblingCount?: number;
  boundaryCount?: number;
}

export function PageIndex(props: PageIndexProps): JSX.Element {
  const { pageCount, currentPage, setCurrentPage, mode, items = [] } = props;

  if (mode === 'dots') {
    return (
      <Container>
        <span>
          {[...Array(pageCount)].map((element, i) => (
            <FaCircle
              size={'0.5em'}
              key={i}
              color={i === currentPage - 1 ? palette.blue : palette.blueDark}
              onClick={() => setCurrentPage(i + 1)}
            />
          ))}
        </span>
      </Container>
    );
  } else {
    return (
      <Container>
        <span>
          {items.map((item, i) => {
            const isNum = typeof item === 'number';
            return (
              <Link
                disabled={!isNum}
                isCurrentPage={item === currentPage}
                key={`${i}_${item}`}
                onClick={
                  typeof item === 'number'
                    ? () => setCurrentPage(item)
                    : undefined
                }
              >
                <LinkText>{item}</LinkText>
              </Link>
            );
          })}
        </span>
      </Container>
    );
  }
}
