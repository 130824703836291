import React from 'react';
import styled, { ThemeProvider, css } from 'styled-components';
import { getTheme, ColorThemeKey } from 'Context';
import { BlueDivider, MobileContainer, PageNavigation } from 'Components';

const Wrapper = styled.section`
  border-left: 1px solid ${({ theme: { color } }) => color.foreground.primary};
  margin-bottom: 3rem;
  position: relative;
  @media only screen and (max-width: 1023px) {
    border: none;
  }
`;

const Content = styled.div`
  padding: 2.5rem;
  @media only screen and (max-width: 1023px) {
    padding: 1rem;
  }

  h1 {
    margin: 0;
  }
`;

const Title = styled.h1(({ theme: { color, font } }) => {
  return css`
    color: ${color.white};
    font-family: ${font.family.commutersSans};
    font-weight: ${font.weights.semiBold};
    font-size: 2.25rem;
    line-height: 1.25;
    text-align: center;
  `;
});

// const HeaderWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding-right: 40px;
// `;

interface TitleSectionProps {
  title: string;
  children: React.ReactNode;
  colorTheme?: ColorThemeKey;
  header?: React.ReactNode;
  prevPage: (() => void) | null;
  nextPage: (() => void) | null;
}

export function TitleSection(props: TitleSectionProps): JSX.Element {
  const { title, children, header, colorTheme, nextPage, prevPage } = props;
  return (
    <ThemeProvider theme={getTheme(colorTheme)}>
      <Wrapper>
        <Title>{title}</Title>
        {header}

        <Content>{children}</Content>

        <MobileContainer>
          <PageNavigation {...{ nextPage, prevPage }} />
        </MobileContainer>
      </Wrapper>

      <BlueDivider />
    </ThemeProvider>
  );
}
