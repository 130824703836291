import { createContext, useContext } from 'react';
import { LoginAttrs, RegisterAttrs } from 'Services';

export type AuthState = 'login' | 'register' | 'guest' | 'success';

export type AuthModalState = AuthState | null;

export interface User {
  id: number;
  username: string;
  email: string;
  lastName?: string;
  firstName?: string;
  isStaff?: boolean;
  isSuperuser?: boolean;
  zipCode?: string;
}

export interface PasswordParams {
  oldPassword?: string;
  password?: string;
  confirmPassword?: string;
}

export interface AppState {
  user: User | null;
  setUser: (user: User | null) => void;
  pageName?: string;
  setPageName: (name: string | undefined) => void;
  clearState: () => void;
  authModalState: AuthModalState;
  setAuthModalState: (authModalState: AuthModalState) => void;
  logout: () => Promise<any>; //eslint-disable-line @typescript-eslint/no-explicit-any
  login: (input: LoginAttrs) => Promise<any>; //eslint-disable-line @typescript-eslint/no-explicit-any
  register: (input: RegisterAttrs) => Promise<any>; //eslint-disable-line @typescript-eslint/no-explicit-any
}

export const AuthContext = createContext<AppState | null>(null);

export const AuthContextProvider = AuthContext.Provider;

export function useAuth(): AppState {
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('useAuth must be called within AuthContextProvider');
  }
  return authContext;
}

// for style library
const defaultAppState = {
  user: null,
  logout: () => alert('logout'),
  login: () => alert('login'),
  register: () => alert('register'),
  setUser: () => alert('setUser'),
  clearState: () => alert('clearState'),
  authModalState: false,
  setAuthModalState: () => alert('setAuthModalState'),
};

export const testAuthStates = {
  loggedIn: {
    ...defaultAppState,
    user: {
      id: 5,
      email: 'johnnybgoode@hotmail.com',
      username: 'JohnnyBGoode',
      firstName: 'Johnny',
      lastName: 'Goode',
      zip: '97202-5047',
    },
    status: 'success',
    logout: (): void => alert('User Logout'),
  },
  loggedOut: {
    ...defaultAppState,
    setAuthModalState: (): void => {
      alert('Open Login Modal');
    },
  },
};
