import { RefObject } from 'react';
import copy from 'copy-to-clipboard';

export function scrollToTop(behavior: ScrollBehavior = 'auto'): void {
  window.scrollTo({ top: 0, behavior });
}

export function scrollToRef(
  ref: RefObject<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  behavior: ScrollBehavior = 'auto'
): void {
  return ref?.current?.scrollIntoView({
    behavior,
    inline: 'start',
  });
}

export function copyUrl(onCopy?: () => void): void {
  const url = window.location.href;
  if (copy(url) && onCopy) {
    onCopy();
  }
}
