import React from 'react';
import styled, { css } from 'styled-components';
import { palette } from 'Context';
import { Link, useLocation } from 'react-router-dom';

interface NavItemProps {
  selected: boolean;
}

const NavItem = styled.div<NavItemProps>(
  ({ theme: { color, font }, selected }) => {
    return css`
      font-family: ${font.family.commutersSans};
      text-align: center;
      padding: 1rem 2rem;
      font-size: 1.25rem;
      background: ${selected ? color.background.primary : ''};
      &:hover {
        color: ${selected ? '' : palette.salmon};
      }
    `;
  }
);

interface NavItemsProps {
  items: {
    text: string;
    path: string;
  }[];
}

export function NavItems({ items }: NavItemsProps): JSX.Element {
  return (
    <nav>
      {items.map(({ text, path }, i) => (
        <Link key={`NavItem${i}_${text}`} to={path}>
          <NavItem selected={true}>{text}</NavItem>
        </Link>
      ))}
    </nav>
  );
}
