import React from 'react';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';

import { Ballot } from 'Components';
import { useElectionQuery } from 'Services';

const Wrapper = styled.div`
  grid-area: contextNav / contextNav / content;
  overflow: auto;
  padding: 1rem 2rem;
`;

export default function BallotPage(): JSX.Element {
  TagManager.dataLayer({
    dataLayer: {
      event: 'componentLoaded',
    },
  });

  const userZip = '80223-1126'; //This will come from the API / user object.

  const guestZip = Cookies.get('zipPlusFourTest');

  // Here we would check auth status.
  // - If the user is logged in, use the zipPlusFour in their account
  // - Else if user has a guest zipPlusFour cookie, use that
  // - Otherwise direct user to sign in

  // (A lot of this will be handled at the route level but is mentioned here for clarity)

  const election = useElectionQuery(guestZip || userZip);

  return (
    <Wrapper>
      <Ballot election={election} />
    </Wrapper>
  );
}
