import styled from 'styled-components';
import { palette } from 'Context/colors';

export const OuterContainer = styled.div`
  position: fixed;
  bottom: 30px;
  z-index: 99999;
`;

export const InnerContainer = styled.div`
  background: ${palette.blueDark};
  color: ${palette.blue};
  box-shadow: 0 0 10px ${palette.blueDarkest};
  cursor: pointer;

  :hover {
    color: white;
  }
`;

export const ScrollOuter = styled(OuterContainer)`
  left: 0;
`;

export const ScrollInner = styled(InnerContainer)`
  border-radius: 0px 20px 20px 0px;
  padding: 7px 12px 5px 8px;
`;

export const ShareOuter = styled(OuterContainer)`
  right: 0;
  display: flex;
  flex-direction: row;
`;

export const ShareInner = styled(InnerContainer)`
  border-radius: 20px 0px 0px 20px;
  padding: 5px 8px 5px 12px;
`;

export const ShareOuterDesktop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const ShareInnerDesktop = styled(InnerContainer)`
  background-color: transparent;
`;
