/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { SmartyStreetsAddress } from 'Context/smartyStreets';
const key = process.env.REACT_APP_SMARTY_STREETS_API_KEY;

export function useAddressData(
  verifiedAddress: SmartyStreetsAddress | null
): any {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchAddressData = async () => {
      if (verifiedAddress === null) return;
      try {
        const url = new URL(
          `https://us-street.api.smartystreets.com/street-address`
        );
        const params = {
          'auth-id': key,
          street: verifiedAddress.street_line,
          city: verifiedAddress.city,
          state: verifiedAddress.state,
        };
        url.search = new URLSearchParams(params).toString();

        const response = await fetch(url.toString());

        if (response.ok) {
          const json = await response.json();
          setData(json);
        }
      } catch (error: any) {
        throw new Error(error);
      }
    };
    fetchAddressData();
  }, [verifiedAddress]);

  return data;
}
