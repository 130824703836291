import { palette } from 'Context';
import React from 'react';
import styled from 'styled-components';

const BulletListContainer = styled.ul`
  text-align: left;
  margin: auto;
  margin-top: 3rem;
  /* margin: 3rem 0 0 0.5em; */
  font-weight: 200;
  font-size: 1.5rem;
  list-style: none;
  max-width: 990px;

  li::before {
    content: '•';
    color: ${palette.blue};
    display: inline-block;
    width: 0.8em;
    margin-left: -0.8em;
    margin-top: -0.2em;
    font-size: 2em;
    vertical-align: middle;
  }

  li {
    margin-bottom: 1.5rem;
    font-weight: 500;
    line-height: 1.4em;
    vertical-align: middle;
    
  }
`;

export const BulletList = (props: { items: string[] }): JSX.Element => {
  const { items } = props;

  return (
    <BulletListContainer>
      {items.map((item: string) => (
        <li key={Math.random()}>{item}</li>
      ))}
    </BulletListContainer>
  );
};
