import styled, { css, useTheme } from 'styled-components';

export * from './BasicInfo';
export * from './Committees';
export * from './NextElection';
export * from './Politician';

interface SmallTitleProps {
  center?: boolean;
}
export const SmallTitle = styled.h4<SmallTitleProps>(({ center }) => {
  const { font, color } = useTheme();
  return css`
    font-family: ${font.family.commutersSans};
    font-weight: ${font.weights.light};
    font-size: 1rem;
    text-transform: uppercase;
    color: ${color.text.primary};
    text-align: ${center ? 'center' : 'inherit'};
  `;
});

export const Heading = styled.h1(() => {
  const { font, color } = useTheme();
  return css`
    font-family: ${font.family.commutersSans};
    font-size: 3.5rem;
    line-height: 1.5;
    padding: 0;
    margin: 0;

    color: ${color.text.primary};
  `;
});

export const SubHeading = styled(Heading)`
  font-size: 2.75rem;
  @media only screen and (max-width: 1023px) {
    font-size: 1.75rem;
    font-weight: 600;
  }
  @media only screen and (max-width: 1280px) {
    font-size: 2rem;
  }
`;

export const BlueText = styled.h3(() => {
  const { font, color } = useTheme();
  return css`
    font-family: ${font.family.commutersSans};
    font-weight: ${font.weights.light};
    font-size: 1.25rem;
    text-transform: uppercase;
    color: ${color.text.secondary};
    padding: 0;
    margin: 0;

    @media only screen and (max-width: 1280px) {
      font-size: 1.125rem;
    }
  `;
});
