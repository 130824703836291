import { palette } from 'Context';
import React from 'react';
import styled, { css } from 'styled-components';

const TabsContainer = styled.div(() => {
  return css`
    margin: -1.5rem -15px 1rem;
    display: flex;
    justify-content: center;
  `;
});

interface Tab {
  onClick: () => void;
  selected?: boolean;
  color?: string;
  children: JSX.Element;
}

const TabButton = styled.button<Tab>(({ theme: { color } }) => {
  return css`
    background: none;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: none;
    padding: 1.5rem 0.5rem;
    border-bottom: ${(props: Tab) =>
      props.selected
        ? '4px solid ' + props.color
        : '4px solid ' + palette.blueDark};
    &:focus {
      outline: none;
    }
    &&& {
      h3 {
        margin: 0;
        color: white;
        font-size: 0.9rem;
        text-transform: uppercase;
      }
      span {
        background-color: ${color.background.secondary};
        font-size: 0.9rem;
        border-radius: 2rem;
        max-height: 1rem;
        margin-left: 1rem;
        text-align: center;
        padding: 0.5rem 1rem;
      }
    }
  `;
});

interface TabProps {
  label: string;
  count: number;
  selected: boolean;
  color: string;
}

export function MobileTabSwitcher(props: {
  tabs: Array<TabProps>;
  handleTabSelect: (arg: string) => void;
}): JSX.Element {
  const { tabs, handleTabSelect } = props;

  return (
    <TabsContainer>
      {tabs.map((tab) => (
        <TabButton
          onClick={() => handleTabSelect(tab.label.toUpperCase())}
          key={tab.label}
          color={tab.color}
          selected={tab.selected}
        >
          <h3>
            {tab.label}
            <span>{tab.count}</span>
          </h3>
        </TabButton>
      ))}
    </TabsContainer>
  );
}
