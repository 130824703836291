import React from 'react';
import { ScrollToTopButton, ShareButton } from '.';

export function MobileStickyButtons(): JSX.Element {
  return (
    <>
      <ScrollToTopButton />
      <ShareButton />
    </>
  );
}
