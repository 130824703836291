import React, { useState, useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import {
  VerticalLine,
  SBubble,
  SSelect,
  SOption,
  Pagination,
  PaginationProps,
  DesktopContainer,
  MobileContainer,
} from 'Components';

const Wrapper = styled.section`
  display: inline-flex;
  flex-direction: row;

  > * {
    margin-right: 0.75rem;
  }
`;

const OuterWrapper = styled.div`
  display: block;
  text-align: center;
`;

const LabelText = styled.span(() => {
  const { font } = useTheme();
  return css`
    align-self: center;
    font-family: ${font.family.commutersSans};
    font-size: 1rem;
  `;
});

interface BaseItem {
  id: string;
  text: string;
  active?: boolean;
  callback: (x?: string) => void;
}

type SortItem = BaseItem;

interface SortButtonProps extends BaseItem {
  activeItem: string;
  setActiveItem: (item: string) => void;
}

function SortButton(props: SortButtonProps): JSX.Element {
  const { text, id, callback, activeItem, setActiveItem } = props;
  return (
    <SBubble
      primary={id === activeItem}
      onClick={() => {
        if (callback) {
          setActiveItem(id);
          callback();
        }
      }}
      style={{ marginRight: '0.5rem' }}
    >
      {text}
    </SBubble>
  );
}
interface SelectListItem extends BaseItem {
  options: SortItem[];
}

function SelectList(props: SelectListItem): JSX.Element {
  const { id, callback, options } = props;
  return (
    <SSelect
      name={id}
      id={id}
      defaultValue={options[0]?.id}
      onChange={(val) => {
        if (callback) callback(val.target.value);
      }}
    >
      {options.map((option) => (
        <SOption key={option.id} value={option.id}>
          {option.text}
        </SOption>
      ))}
    </SSelect>
  );
}

interface SortListProps {
  sortOptions: SortItem[];
}

function SortList(props: SortListProps): JSX.Element {
  const { sortOptions } = props;
  const [activeItem, setActiveItem] = useState<string>(sortOptions[0].id);

  // we only want to sort once, even if the list changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => sortOptions[0].callback(), []);

  return (
    <div>
      <DesktopContainer>
        {sortOptions.map((item, idx) => (
          <SortButton
            key={`${idx}_${item.id}_button`}
            {...item}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        ))}
      </DesktopContainer>
      <MobileContainer>
        <SelectList
          id="sortlist-mobile"
          text=""
          options={sortOptions}
          callback={(val) => {
            if (val) {
              setActiveItem(val);
              const item = sortOptions.find((e) => e.id === val);
              if (item && item.callback) item.callback();
            }
          }}
        />
      </MobileContainer>
    </div>
  );
}

export interface SectionHeaderProps {
  paginationProps?: PaginationProps;
  sortOptions?: SortItem[];
  selectList?: SelectListItem;
}

export function SectionHeader(props: SectionHeaderProps): JSX.Element {
  const { sortOptions, selectList, paginationProps } = props;
  return (
    <OuterWrapper>
      <Wrapper>
        {selectList && <SelectList {...selectList} />}

        {sortOptions && selectList && <VerticalLine marginRight="0.75rem" />}

        {sortOptions && (
          <>
            <LabelText>Sort by</LabelText>
            <SortList sortOptions={sortOptions} />
          </>
        )}
      </Wrapper>
      {paginationProps && paginationProps.pageCount > 1 && (
        <Pagination {...paginationProps} />
      )}
    </OuterWrapper>
  );
}
