import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  FlagSection,
  Initiative,
  ShareButton,
  FlagLoader,
  MobileStickyButtons,
  MetaTags,
  SButton,
} from 'Components';
import { palette, BallotMeasureDetail } from 'Context';

import { logoSVG } from 'Images';
import { useMeasures } from 'Services';

const MobileContainer = styled.div`
  position: relative;
  margin: 15px 10px 0px 10px;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

const DesktopContainer = styled.div`
  position: relative;
  h1 {
    font-weight: 500;
    font-size: 2.6rem;
  }
  @media only screen and (max-width: 1023px) {
    display: none;
  }

  a {
    text-decoration-line: none;
    color: ${palette.blueText};
    /* background-image: linear-gradient(to right, white 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x; */
  }
  a:hover {
    color: ${palette.blueLight};
  }
`;

const Logo = styled.img.attrs(() => ({
  src: logoSVG,
  alt: '🇺🇸 Populist',
  width: '500px',
}))`
  margin: 1rem 0 3rem 0;
  cursor: pointer;
`;

const CardGrid = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 3rem;
`;

export default function BallotMeasurePage(): JSX.Element {
  const history = useHistory();

  const handleMeasureClick = (measureId: string | number) => {
    history.push(`/colorado/${measureId}`);
  };

  const currentBallotMeasures = [
    {
      id: 'amendment-d',
      ballotOrder: 1,
      ballotMeasureCode: 'Amendment D',
      measureType: 'amendment',
      shortTitle: 'New 23rd Judicial District Judges',
      issueTags: ['Government'],
    },
    {
      id: 'amendment-e',
      ballotOrder: 2,
      ballotMeasureCode: 'Amendment E',
      measureType: 'amendment',
      shortTitle: 'Extend Homestead Exemption to Gold Star Spouses',
      issueTags: ['Veterans', 'Housing', 'Taxes'],
    },
    {
      id: 'amendment-f',
      ballotOrder: 3,
      ballotMeasureCode: 'Amendment F',
      measureType: 'amendment',
      shortTitle: 'Changes to Charitable Gaming Operations',
      issueTags: ['Gaming'],
    },
    {
      id: 'proposition-ff',
      ballotOrder: 4,
      ballotMeasureCode: 'Proposition FF',
      measureType: 'proposition',
      shortTitle: 'Healthy School Meals for All',
      issueTags: ['Education', 'Healthcare', 'Taxes'],
    },
    {
      id: 'proposition-gg',
      ballotOrder: 5,
      ballotMeasureCode: 'Proposition GG',
      measureType: 'proposition',
      shortTitle: 'Add Tax Information Table to Petitions and Ballots',
      issueTags: ['Government', 'Taxes'],
    },
    {
      id: 'proposition-121',
      ballotOrder: 6,
      ballotMeasureCode: 'Proposition 121',
      measureType: 'proposition',
      shortTitle: 'State Income Tax Rate Reduction',
      issueTags: ['Taxes'],
    },
    {
      id: 'proposition-122',
      ballotOrder: 7,
      ballotMeasureCode: 'Proposition 122',
      measureType: 'proposition',
      shortTitle: 'Access to Natural Medicine',
      issueTags: ['Drugs'],
    },
    {
      id: 'proposition-123',
      ballotOrder: 8,
      ballotMeasureCode: 'Proposition 123',
      measureType: 'proposition',
      shortTitle:
        'Dedicated State Income Tax Revenue for Affordable Housing Programs',
      issueTags: ['Housing'],
    },
    {
      id: 'proposition-124',
      ballotOrder: 9,
      ballotMeasureCode: 'Proposition 124',
      measureType: 'proposition',
      shortTitle: 'Increase Allowable Liquor Store Locations',
      issueTags: ['Alcohol'],
    },
    {
      id: 'proposition-125',
      ballotOrder: 10,
      ballotMeasureCode: 'Proposition 125',
      measureType: 'proposition',
      shortTitle: 'Allow Grocery and Convenience Stores to Sell Wine',
      issueTags: ['Alcohol'],
    },
    {
      id: 'proposition-126',
      ballotOrder: 11,
      ballotMeasureCode: 'Proposition 126',
      measureType: 'proposition',
      shortTitle: 'Third-Party Delivery of Alcohol Beverages',
      issueTags: ['Alcohol'],
    },
  ];

  return (
    <>
      <MetaTags
        title="Colorado State Ballot Measures 2022"
        description="This election there are eleven statewide ballot measures that
              Coloradans will vote on. Learn about them below and then submit
              your ballot early"
      />
      <MobileContainer>
        <MobileStickyButtons />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a href="https://www.populist.us/ballot">
            <SButton
              style={{
                background: palette.blue,
                marginBottom: '1rem',
                width: '18rem',
              }}
            >
              View Your Ballot
            </SButton>
          </a>
        </div>

        {currentBallotMeasures.map((measure: any) => (
          <Initiative
            key={measure.id}
            id={measure.id}
            name={measure.shortTitle}
            code={measure.ballotMeasureCode}
            issueTags={measure.issueTags}
            onClick={() => handleMeasureClick(measure.id)}
          />
        ))}
      </MobileContainer>
      <DesktopContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <a href="https://www.populist.us/register">
            <Logo />
          </a>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <a href="https://www.populist.us/register">
              <SButton style={{ background: palette.blue }}>Register</SButton>
            </a>
            <a href="https://www.populist.us/login">
              <SButton>Login</SButton>
            </a>
          </div>
        </div>
        <FlagSection colorTheme="darkBlue" title="2022 General Election">
          <>
            <ShareButton type="desktop" />
            <h1>Colorado Statewide Ballot Measures</h1>
            <p>
              This election there are eleven statewide ballot measures that
              Coloradans will vote on. Learn about them below and then submit
              your ballot early at a{' '}
              <a
                href="https://www.sos.state.co.us/pubs/elections/VIP.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                local dropbox
              </a>
              . You can also mail in your ballot or go to a{' '}
              <a
                href="https://www.sos.state.co.us/pubs/elections/VIP.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                polling location
              </a>{' '}
              on <strong>November 8th</strong>.
            </p>
            <a href="https://www.populist.us/ballot">
              <SButton style={{ background: palette.blue }}>
                View Your Ballot
              </SButton>
            </a>
            <CardGrid>
              {currentBallotMeasures.map((measure: any) => (
                <Initiative
                  key={measure.id}
                  id={measure.id}
                  name={measure.shortTitle}
                  code={measure.ballotMeasureCode}
                  issueTags={measure.issueTags}
                  onClick={() => handleMeasureClick(measure.id)}
                />
              ))}
            </CardGrid>
          </>
        </FlagSection>
      </DesktopContainer>
    </>
  );
}
