const S3 = 'https://populist-static-assets.s3.amazonaws.com/images/';

export const chevron = `${S3}Chevron.svg`;
export const linkExternal = `${S3}LinkExternal.svg`;
export const loaderBlue = `${S3}LoaderBlue.gif`;
export const logoFlag = `${S3}LogoFlag.svg`;
export const logoMark = `${S3}LogoMark_noPadding.svg`;
export const logoType = `${S3}LogoType.svg`;
export const logoPNG = `${S3}PopulistLogo.png`;
export const logoSVG = `${S3}PopulistLogo.svg`;
export const ballotMeasureMVP = `${S3}BallotMeasureMVP.gif`;
export const desktopBallot = `${S3}DesktopBallot.png`;
export const mobileNews = `${S3}MobileNews.png`;
export const connections = `${S3}Connections.png`;

export default {
  chevron,
  linkExternal,
  loaderBlue,
  logoFlag,
  logoMark,
  logoType,
  logoPNG,
  logoSVG,
  ballotMeasureMVP,
  desktopBallot,
  mobileNews,
  connections,
};
