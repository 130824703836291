import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { PoliticianProps, BoxContainer, Candidate } from 'Components';

import { SmallTitle, BlueText, SubHeading } from '.';

const ElectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    margin: 0 1.5rem;
  }

  @media only screen and (min-width: 1024px) {
    > div {
      padding-right: 1rem;
    }
  }
`;

export interface NextElectionProps {
  id: string | number;
  name: string;
  date: string;
  position: string;
  location: string;
  opponents: PoliticianProps[];
}

const Area = styled.div`
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`;

export function NextElection(props: NextElectionProps): JSX.Element {
  const { date, location, name, position, opponents } = props;
  return (
    <ElectionWrapper>
      <Area>
        <SmallTitle>Next Election</SmallTitle>
        <BoxContainer outline padding="1.5rem 3rem">
          <BlueText>{name}</BlueText>
          <SubHeading>{moment(date).format('MMM Do YYYY')}</SubHeading>
        </BoxContainer>
      </Area>
      <Area>
        <SmallTitle>Running For</SmallTitle>
        <BoxContainer outline padding="1.5rem 3rem">
          <BlueText>{location}</BlueText>
          <SubHeading>{position}</SubHeading>
        </BoxContainer>
      </Area>
      <div>
        <SmallTitle>Opponent{opponents.length > 1 && 's'}</SmallTitle>
        <BoxContainer outline padding="1.5rem 3rem" center>
          {opponents.map((opponent) => (
            <Candidate
              key={opponent.id}
              id={opponent.id}
              name={opponent.name}
              src={opponent.src}
              party={opponent.party || 'other'}
            />
          ))}
        </BoxContainer>
      </div>
    </ElectionWrapper>
  );
}
