import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NewsCard, FlagLoader, ContextNav } from 'Components';

interface Article {
  source: {
    [name: string]: string;
  };
  title: string;
  url: string;
  urlToImage: string;
}

const Wrapper = styled.div`
  grid-area: content;
  overflow: auto;
  padding: 0 3rem;
`;

export default function NewsPage(): JSX.Element {
  const [currentArticles, setCurrentArticles] = useState([]);

  const [query, setQuery] = useState('everything?q=politics&');

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const baseUrl = 'https://newsapi.org/v2/';

        const apiKey = 'apiKey=f50bffea49554065a190e56f890111cc';

        const url = baseUrl + query + apiKey;

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error('Something went wrong fetching from the NewsAPI');
        }

        if (response.ok) {
          const json = await response.json();
          setCurrentArticles(json.articles);
        }
      } catch (error) {
        throw new Error('Something went wrong fetching from the NewsAPI');
      }
    };
    fetchArticles();
  }, [query]);

  return (
    <>
      <ContextNav setQuery={setQuery} />
      <Wrapper>
        {currentArticles.length === 0 && <FlagLoader />}
        {currentArticles.map((article: Article) => (
          <NewsCard
            key={article.url}
            sourceName={article.source.name}
            headline={article.title}
            url={article.url}
            imageURL={article.urlToImage}
          />
        ))}
      </Wrapper>
    </>
  );
}
