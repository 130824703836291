import React from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';

import { palette } from 'Context';
import { chevron } from 'Images';

const SwipeNav = styled.div`
  color: ${palette.blue};
  font-family: ${({ theme }) => theme.font.family.commutersSans};
  margin: 1rem;
  display: inline;
  user-select: none;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  user-select: none;

  svg {
    margin: 0 0.5rem;
  }
`;

const ChevronLeft = styled.img.attrs(() => ({
  src: chevron,
  alt: '<',
}))(({ onClick }) => ({ height: 20, cursor: onClick ? 'pointer' : 'auto' }));

const ChevronRight = styled.img.attrs(() => ({
  src: chevron,
  alt: '>',
}))(({ onClick }) => ({
  height: 20,
  transform: 'scaleX(-1)',
  cursor: onClick ? 'pointer' : 'auto',
}));

export interface PageNavigationProps {
  prevPage: (() => void) | null;
  nextPage: (() => void) | null;
}

export function PageNavigation(props: PageNavigationProps): JSX.Element {
  const { prevPage, nextPage } = props;

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: (eventData) => {
      console.log('swiped left', eventData);
      prevPage && prevPage();
    },
    onSwipedRight: (eventData) => {
      console.log('swiped right', eventData);
      nextPage && nextPage();
    },
  });

  return (
    <Container>
      <ChevronLeft
        color={prevPage ? palette.white : palette.blue}
        onClick={prevPage ? () => prevPage() : undefined}
      />
      {
        //<Draggable handleDrag={(x, y) => console.log('drag', x, y)}>
      }
      <SwipeNav {...swipeHandlers}>SWIPE</SwipeNav>

      <ChevronRight
        color={prevPage ? palette.white : palette.blue}
        onClick={nextPage ? () => nextPage() : undefined}
      />
    </Container>
  );
}
