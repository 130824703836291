import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from 'axios';
import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module';

const BASE_URL = process.env.REACT_APP_POPULIST_API_URL || '//';

axios.defaults.baseURL = BASE_URL;

const environment = process.env.NODE_ENV;

console.log({
  environment,
  BASE_URL,
  'axios.defaults.baseURL': axios.defaults.baseURL,
});

TagManager.initialize({
  gtmId: 'GTM-W8MSXH4',
  dataLayer: {
    userId: '179836548',
    userProject: 'Populist',
  },
  events: {
    componentLoaded: 'componentLoaded',
  },
});

const root = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
