import styled, { css, useTheme } from 'styled-components';

export const emailExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const zipExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

export interface STextInputProps {
  error?: boolean;
}

export const STextInput = styled.input.attrs(({ type }) => ({
  ...(type ? { type } : { type: 'text' }),
}))<STextInputProps>(({ theme: { color, font }, error }) => {
  return css`
    background: ${color.white};
    border: 1px solid ${error ? color.error : '#bbbbbb'};
    box-sizing: border-box;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 10px;
    font-family: ${font.family.proximaNova};

    :focus {
      outline: none;
    }
  `;
});

export const SCheckbox = styled(STextInput).attrs(() => ({ type: 'checkbox' }))`
  border-radius: 4px;
  cursor: pointer;
`;

interface StyledButtonProps {
  outlined?: boolean;
  fullWidth?: boolean;
}

const useButtonStyles = (props: StyledButtonProps) => {
  const { outlined, fullWidth } = props;
  const type = outlined ? 'outlined' : 'solid';

  const { color, font } = useTheme();

  return css`
    font-family: ${font.family.proximaNova};
    padding: 0.5rem 1rem;
    font-size: 1.125rem;
    line-height: 1.5;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;

    // default state
    color: ${color.button[type].default.text};
    background: ${color.button[type].default.background};
    border: 2px solid ${color.button[type].default.border};

    // hover state
    :focus,
    :hover {
      color: ${color.button[type].hover.text};
      background: ${color.button[type].hover.background};
      border-color: ${color.button[type].hover.border};
    }

    // inactive state
    :disabled,
    :disabled:hover {
      color: ${color.button.inactive.text};
      background: ${color.button.inactive.background};
      border-color: ${color.button.inactive.border};
      cursor: default;
    }

    // click state
    :active {
      color: ${color.button[type].click.text};
      background: ${color.button[type].click.background};
      border-color: ${color.button[type].click.border};
    }

    ${fullWidth &&
    css`
      align-self: center;
      width: 100%;
      margin: 1rem auto;
      height: '3.75rem';
    `}

    transition: all 0.3s;
  `;
};

export const SButton = styled.button<StyledButtonProps>(useButtonStyles);
export const SSubmit = styled.input.attrs(() => ({
  type: 'submit',
}))<StyledButtonProps>(useButtonStyles);

export const SOption = styled.option(() => {
  const { color, font } = useTheme();

  return css`
    background-color: ${color.background.primary};
    color: ${color.foreground.primary};
    cursor: pointer;
    text-align: center;
    padding: 5px;
    margin: 5px;
    font-family: ${font.family.proximaNova};
    padding: 8px 16px;
    font-size: 18px;
    line-height: 22px;
  `;
});

interface BubbleProps {
  primary?: boolean;
}

const useBubbleStyles = ({ primary = false }: BubbleProps) => {
  const { font, color } = useTheme();
  return css`
    font-family: ${font.family.commutersSans};
    font-size: 1rem;
    color: ${primary ? color.background.primary : color.text.primary};
    background-color: ${primary
      ? color.foreground.primary
      : color.background.primary};
    border: 1px solid ${color.foreground.primary};
    border-radius: 40px;
    padding: 0.5rem 1.5rem;
    cursor: pointer;

    :hover {
      background-color: ${primary
        ? color.foreground.primary // if we want a special hover color we need to define it in the theme
        : color.background.secondary};
    }

    :focus {
      outline: none;
    }
  `;
};

export const SBubble = styled.button<BubbleProps>(useBubbleStyles);

export const SSelect = styled.select<BubbleProps>(useBubbleStyles);
