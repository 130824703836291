import React from 'react';
import ReactAvatar, { ReactAvatarProps } from 'react-avatar';
import styled, { css, useTheme } from 'styled-components';
import { getPartyColor, Party } from 'Context';

interface BadgeProps {
  background?: string;
  text?: string;
  size: string;
  fontSize: string;
}

export interface AvatarProps extends ReactAvatarProps {
  badge?: BadgeProps;
}

export interface PartyAvatarProps extends ReactAvatarProps {
  party: Party;
  badgeSize?: string;
  badgeFontSize?: string;
}

const AvatarWrapper = styled.div`
  position: relative;
  padding: 0;
  margin: auto;
  display: inline-flex;
`;

interface BadgeWrapperProps {
  background: BadgeProps['background'];
  size: string;
  fontSize: string;
}

const BadgeWrapper = styled.div<BadgeWrapperProps>(
  ({ theme: { color, font }, background, size, fontSize }) => {
    return css`
      position: absolute;
      bottom: 3px;
      right: 0;
      border-radius: 50px;
      width: ${size};
      height: ${size};
      font-weight: ${font.weights.semiBold};
      font-size: ${fontSize};
      text-transform: uppercase;
      color: ${color.white};
      font-family: ${font.family.proximaNova};
      background: ${background || color.grey};
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    `;
  }
);

const BadgeText = styled.span(({ theme: { font } }) => {
  return css`
    align-self: center;
    margin: auto;
    justify-content: center;
    text-align: center;
    font-weight: ${font.weights.semiBold};
  `;
});

function Badge(props: BadgeProps): JSX.Element {
  const { text, background, size, fontSize } = props;
  return (
    <BadgeWrapper size={size} fontSize={fontSize} background={background}>
      <BadgeText>{text}</BadgeText>
    </BadgeWrapper>
  );
}

const Wrapper = styled.div`
  display: inline;
`;

export function Avatar(props: AvatarProps): JSX.Element {
  const { badge, name = '', round = true, ...rest } = props;

  const { color } = useTheme();

  const avatarProps = {
    color: color.foreground.primary,
    ...rest,
    round, // default to round Avatars
    name,
    ...{ style: rest.onClick && { cursor: 'pointer' } },
  };

  return (
    <Wrapper>
      <AvatarWrapper>
        <ReactAvatar {...avatarProps} />
        {badge && <Badge {...badge} />}
      </AvatarWrapper>
    </Wrapper>
  );
}

export function PartyAvatar({
  party,
  badgeSize = '1.25rem',
  badgeFontSize = '0.75rem',
  ...rest
}: PartyAvatarProps): JSX.Element {
  const badge = {
    background: getPartyColor(party),
    text: party.slice(0, 1).toUpperCase(),
    size: badgeSize,
    fontSize: badgeFontSize,
  };
  return <Avatar badge={badge} {...rest} />;
}
