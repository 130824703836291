import { ColorThemeKey, getColorTheme } from './colors';
export * from './colors';

export const spacing = {
  xs: '5px',
  sm: '10px',
  md: '15px',
  lg: '20px',
  xl: '25px',
  xxl: '30px',
  xxxl: '40px',
};

const font = {
  family: {
    commutersSans:
      "'Commuters Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    proximaNova:
      "'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  weights: {
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
};

const sizes = {
  xs: 0,
  sm: 600,
  medium: 960,
  mobile: 1024,
  large: 1280,
  extraLarge: 1920,
};
const breakpoints = sizes; /*Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});*/

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTheme = (colorTheme?: ColorThemeKey) => ({
  font,
  spacing,
  breakpoints,
  color: getColorTheme(colorTheme),
});

export const theme = getTheme();

export const themes = {
  default: theme,
  ratings: getTheme('ratings'),
  endorsements: getTheme('endorsements'),
  financials: getTheme('financials'),
  legislation: getTheme('legislation'),
  quote: getTheme('quote'),
  news: getTheme('news'),
  form: getTheme('form'),
  yes: getTheme('green'),
  no: getTheme('salmon'),
};
