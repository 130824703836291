import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { PoliticianDetail, FlagLoader } from 'Components';
import { useAuth } from 'Context';
import { usePoliticianBySlugQuery } from '../../../../generated';

export default function PoliticianPage(): JSX.Element {
  const { slug } = useParams<{ slug: string }>();

  const { data, error, isError, isLoading, refetch } = usePoliticianBySlugQuery(
    { slug }
  );

  const history = useHistory();

  const { setPageName } = useAuth();

  useEffect(() => {
    refetch(); // When PoliticianId changes, refetch
  }, [slug, refetch]);

  useEffect(() => {
    setPageName(data?.politicianBySlug.fullName); // When name changes, set global page name.
    return () => setPageName(undefined); // When unmouting, reset
  }, [data?.politicianBySlug.fullName, setPageName]);

  if (isError) {
    console.error(`Politician Page API Error: ${error}`);
    history.push('/404');
  }

  if (isLoading) return <FlagLoader />;

  if (!isLoading && (!data || typeof data !== 'object')) {
    console.error(
      'Politician Page Error. API data is invalid / empty. Value of data:',
      data
    );
    history.push('/404');
  }

  return <pre>{JSON.stringify(data, null, 4)}</pre>;
}
