import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FaShareSquare } from 'react-icons/fa';
import {
  ShareInner,
  ShareOuter,
  ShareInnerDesktop,
  ShareOuterDesktop,
} from './shared';

import { copyUrl } from 'Utils';

export const Bubble = styled.span(({ theme: { color } }) => {
  return css`
    background: #d6d6d6;
    box-shadow: 0px 0px 10px ${color.background.primary};
    border-radius: 20px;
    display: flex;
    align-items: center;

    margin-right: 15px;

    opacity: 1;
    animation-name: fadeInOutOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2.5s;

    @keyframes fadeInOutOpacity {
      0% {
        opacity: 0;
      }
      15% {
        opacity: 1;
      }
      85% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  `;
});

const BubbleText = styled.span(({ theme: { color, font } }) => {
  return css`
    font-family: ${font.family.proximaNova};
    font-weight: ${font.weights.medium};
    font-size: 14px;
    text-align: center;
    padding: 0 15px;

    color: ${color.greyDarkest};
  `;
});

interface ShareButtonProps {
  type?: 'desktop' | 'mobile';
}

export function ShareButton({
  type = 'mobile',
}: ShareButtonProps): JSX.Element {
  const [displayBubble, setDisplayBubble] = useState(false);
  const onCopy = () => {
    setDisplayBubble(true);
    setTimeout(() => setDisplayBubble(false), 2500);
  };

  const Outer = type === 'desktop' ? ShareOuterDesktop : ShareOuter;
  const Inner = type === 'desktop' ? ShareInnerDesktop : ShareInner;

  const extraStyle = type === 'desktop' ? { padding: '10px 15px' } : {};

  return (
    <Outer>
      {displayBubble && (
        <Bubble style={extraStyle}>
          <BubbleText>URL Copied</BubbleText>
        </Bubble>
      )}
      <Inner style={extraStyle} onClick={() => copyUrl(onCopy)}>
        <FaShareSquare size={22} />
      </Inner>
    </Outer>
  );
}
