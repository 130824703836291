import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import useOnClickOutside from 'use-onclickoutside';
import { FaCog } from 'react-icons/fa';
import { Avatar } from 'Components';
import { useAuth } from 'Context';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  margin-top: auto;
  margin-bottom: 2.5rem;
`;

const MenuLink = styled.a(({ theme: { font, color } }) => {
  return css`
    font-family: ${font.family.commutersSans};
    font-size: 16px;
    line-height: 40px;
    /* or 250% */
    text-align: center;
    color: ${color.text.primary};
    cursor: pointer;
    display: block;
  `;
});

const CogWrapper = styled.div`
  color: ${({ theme: { color } }) => color.text.secondary};
  align-self: center;
  display: none; //We will bring this back when needed
`;

export const GreyList = styled.ul(({ theme: { color, font } }) => {
  return css`
    background: ${color.greyLight};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    list-style-type: none;
    padding-inline-start: 0;
    padding-inline-end: 0;

    > li {
      font-family: ${font.family.proximaNova};
      font-size: 18px;
      line-height: 40px;
      color: ${color.black};
      border-top: 1px solid ${color.grey};
      padding: 0.25rem 1rem;
      :hover {
        cursor: pointer;
        background: #bbb;
        border-top: 1px solid #bbb;
      }
      :hover + li {
        border-top: 1px solid #bbb;
      }
    }

    > li:first-child {
      border-top: none;
      :hover {
        border-radius: 20px 20px 0 0;
      }
    }

    > li:last-child {
      :hover {
        border-radius: 0 0 20px 20px;
      }
    }
  `;
});

const UserWrapper = styled.div`
  position: relative;
`;

const UserOptions = styled.div`
  position: absolute;
  bottom: -25px;
  right: -90px;
`;

export function UserMenu({
  avatarSize = '80px',
}: {
  avatarSize?: string;
}): JSX.Element {
  const { user, setAuthModalState, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  const history = useHistory();

  const userOptionsRef = useRef(null);
  useOnClickOutside(userOptionsRef, () => setMenuOpen(false));

  const signout = () => {
    logout();
    setMenuOpen(false);
  };

  const editAccount = () => {
    history.push('/settings/profile');
    setMenuOpen(false);
  };

  return (
    <Wrapper>
      {user ? (
        <UserWrapper>
          {menuOpen && (
            <UserOptions ref={userOptionsRef}>
              <GreyList>
                <li onClick={() => editAccount()}>Edit Account</li>
                <li onClick={signout}>Sign Out</li>
              </GreyList>
            </UserOptions>
          )}
          <Avatar
            size={avatarSize}
            name={`${user.firstName} ${user.lastName}`}
            onClick={() => setMenuOpen(!menuOpen)}
          />
        </UserWrapper>
      ) : (
        <>
          <CogWrapper>
            <FaCog size={40} />
          </CogWrapper>
          <MenuLink onClick={() => setAuthModalState('login')}>
            Sign In
          </MenuLink>
          <MenuLink onClick={() => setAuthModalState('register')}>
            Register
          </MenuLink>
        </>
      )}
    </Wrapper>
  );
}
