import React from 'react';
import { useForm } from 'react-hook-form';
import { LoginAttrs } from 'Services';
import { useAuthFlowActions } from 'Context';

import {
  SHeading,
  SLink,
  SButton,
  SSubmit,
  TextInput,
  SDivider,
  emailExp,
  ErrorText,
} from 'Components';

import { HIDE_GUEST } from 'Flags';

export default function Login(): JSX.Element {
  const { setTab, submitLogin, error } = useAuthFlowActions();
  const { register, handleSubmit, formState } = useForm<LoginAttrs>({
    mode: 'onChange',
  });

  const { errors, isValid, isDirty } = formState;

  return (
    <form onSubmit={handleSubmit(submitLogin)}>
      <SHeading>Sign in</SHeading>

      <SDivider />

      <TextInput
        {...register('username', { required: 'Email is required' })}
        error={errors.username}
        type="email"
        label="Email"
        pattern={emailExp}
      />

      <TextInput
        {...register('password', {
          required: 'Password is required',
          minLength: { value: 8, message: '8 characters minimum' },
        })}
        type="password"
        error={errors.password}
        label="Password"
      />

      <SSubmit
        readOnly
        value="Sign in"
        fullWidth
        disabled={!isDirty || !isValid}
      />

      {!HIDE_GUEST && (
        <SButton fullWidth onClick={() => setTab('guest')} outlined>
          Continue as a guest
        </SButton>
      )}

      {error && <ErrorText>{error}</ErrorText>}

      <SLink onClick={() => alert('forgot your password tab')}>
        Forgot your password?
      </SLink>

      <br />

      <SLink onClick={() => setTab('register')}>Create an Account</SLink>
    </form>
  );
}
