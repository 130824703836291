import { createContext, useContext } from 'react';
import { LoginAttrs, RegisterAttrs } from 'Services';
import { AuthState } from '.';

export interface AuthFlowContextParams {
  setTab: (tab: AuthState) => void;
  onCancel?: () => void;
  submitRegister: (input: RegisterAttrs) => void;
  submitLogin: (input: LoginAttrs) => void;
  setGuestCookie: (zip: string) => void;
  error?: string;
  setError: (error?: string) => void;
  onContinue: () => void;
  hardRoutes: boolean;
}

export const AuthFlowContext = createContext<AuthFlowContextParams | null>(
  null
);

export const useAuthFlowActions = (): AuthFlowContextParams => {
  const formActionContext = useContext(AuthFlowContext);
  if (formActionContext === null) {
    throw new Error(
      'useAuthFlowActions must be called within a AuthFlowContext tag'
    );
  }
  return formActionContext;
};
