import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';

import {
  MobileContainer,
  DesktopContainer,
  SButton,
  Draggable,
  MarkdownContainer,
  OrganizationHeader,
  HeaderFooterBox,
  PageNavigation,
  PageIndex,
} from 'Components';

import { scrollToRef } from 'Utils';

import { usePagination } from 'Hooks/usePagination';
import { useDidMountEffect } from 'Hooks/useDidMountEffect';

interface SubCard {
  title: string;
  author: {
    name: string;
    thumbnailImage: string;
  };
  text: string;
}

const MobileMoreInfo = (props: { infoItems: Array<SubCard> }): JSX.Element => {
  const { infoItems } = props;

  const {
    nextPage,
    prevPage,
    currentPage,
    setCurrentPage,
    pageCount,
    currentData,
  } = usePagination<SubCard>(infoItems ?? [], 1);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDrag = (mx: number, my: number) => {
    if (mx < -100) {
      nextPage();
    }
    if (mx > 100) {
      prevPage();
    }
  };

  console.log(currentData());

  return (
    <MobileContainer>
      <PageIndex
        pageCount={pageCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {currentData().map((item: SubCard) => (
        <Draggable key={Math.random()} handleDrag={handleDrag}>
          <HeaderFooterBox>
            <h5>{item.title}</h5>
            <MarkdownContainer>
              <Markdown>{item.text}</Markdown>
            </MarkdownContainer>
            {item.author && (
              <footer style={{ justifyContent: 'left' }}>
                <OrganizationHeader organization={item.author} />
              </footer>
            )}
          </HeaderFooterBox>
        </Draggable>
      ))}
      <PageNavigation prevPage={prevPage} nextPage={nextPage} />
    </MobileContainer>
  );
};

export const BallotMeasureMoreInfo = (props: {
  infoItems: SubCard[];
  scrollRef?: any;
}): JSX.Element => {
  const { infoItems, scrollRef } = props;

  const [visibleCards, setVisibleCards] = useState(3);

  const [scrollPos, setScrollPos] = useState<any>();

  const detailPage: HTMLElement = document.getElementById('detail-page')!;

  const handleScroll = () => {
    // when component re-renders, it causes detailPage.scrollTop to be 0. We don't want
    // to update state if scrollTop is 0.
    if (detailPage.scrollTop !== 0) {
      setScrollPos(detailPage.scrollTop);
    }
  };

  // add event listener to detailPage when component mounts
  useEffect(() => {
    if (detailPage) detailPage.addEventListener('scroll', handleScroll);
    if (detailPage)
      return () => detailPage.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailPage]);

  // restore scroll whenever visibleCards changes
  useDidMountEffect(() => {
    if (visibleCards !== 3) {
      detailPage.scrollTo(0, scrollPos);
    } else {
      scrollToRef(scrollRef, 'smooth');
    }
  }, [visibleCards]);

  const handleShowMoreLess = () => {
    if (visibleCards < infoItems.length) {
      setVisibleCards((prevState) => Math.min(infoItems.length, prevState + 3));
    } else {
      setVisibleCards(3);
    }
  };

  if (infoItems)
    return (
      <>
        <MobileMoreInfo infoItems={infoItems} />
        <DesktopContainer id="info-container">
          {infoItems
            .slice(0, visibleCards)
            .map((item: SubCard, index: number) => (
              <HeaderFooterBox key={item.title + index}>
                <h5>{item.title}</h5>
                <MarkdownContainer>
                  <Markdown>{item.text}</Markdown>
                </MarkdownContainer>
                {item.author && (
                  <footer style={{ justifyContent: 'left' }}>
                    <OrganizationHeader organization={item.author} />
                  </footer>
                )}
              </HeaderFooterBox>
            ))}
          {infoItems.length > 3 && (
            <footer>
              <SButton outlined onClick={() => handleShowMoreLess()}>
                View {visibleCards >= infoItems.length ? 'Less' : 'More'}
              </SButton>
            </footer>
          )}
        </DesktopContainer>
      </>
    );
  return <div />;
};
