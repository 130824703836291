import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Redirect, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import {
  SButton,
  FlagSection,
  ChipsContainer,
  MobileContainer,
  DesktopContainer,
  BulletList,
  BallotMeasureVoteMeaning,
  BallotMeasureArguments,
  BallotMeasureMoreInfo,
  MobileStickyButtons,
  FlagLoader,
  ShareButton,
  BallotMeasureEndorsements,
  MetaTags,
} from 'Components';
import { BallotMeasureDetail, palette } from 'Context';
import { mockMeasures } from 'Services';
import { chevron } from 'Images';

const MobileDetails = styled.article(() => {
  return css`
    text-align: center;
    padding-bottom: 75px;
    padding-top: 1rem;
    margin: 0px 15px 0px;

    border-top: 1px solid ${palette.blueDark};

    article:nth-child(1) {
      margin-bottom: 1rem;
    }

    h2 {
      text-transform: uppercase;
      font-weight: 100;
      font-size: 1.2rem;
    }

    h1.measure-name {
      font-weight: 600;
    }

    h3 {
      font-size: 2rem;
      margin: 3rem 0 2rem;
    }
    li {
      font-size: 1.2rem;
      margin-left: -10px;
    }
    p {
      margin-left: 15px;
      margin-right: 15px;
    }
  `;
});

const DetailsContainer = styled.article(({ theme: { color } }) => {
  return css`
    background: ${color.background.primary};
    text-align: center;

    h2 {
      text-transform: uppercase;
      font-weight: 100;
    }

    h1.measure-name {
      font-size: 3.5rem;
      font-weight: 600;
      margin-bottom: 3rem;
    }

    h3 {
      font-size: 2.4rem;
      margin: 3rem 0 2rem;
    }
  `;
});

const DetailsSection = styled.section(({ theme: { color, font } }) => {
  return css`
    border-top: 1px solid ${palette.blueDark};
    margin-top: 3rem;

    .centered {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      width: 100%;
    }

    h3 {
      font-family: ${font.family.commutersSans};
      font-weight: 600;

      span {
        background-color: ${color.background.secondary};
        font-family: ${font.family.proximaNova};
        font-size: 1.5rem;
        border-radius: 2em;
        max-height: 1em;
        margin-left: 1rem;
        text-align: center;
        padding: 0.5rem 1rem 0.4rem;
        vertical-align: middle;
      }
    }

    p {
      text-align: left;
      line-height: 2rem;
    }

    a {
      text-decoration: none;
    }

    .narrow {
      max-width: 990px;
      margin: auto;
      margin-bottom: 1rem;
    }
  `;
});

// --------------- BACK BUTTON IN MOBILE HEADER

const ChevronLeft = styled.img.attrs(() => ({
  src: chevron,
  alt: '<',
}))({ height: 30 });

const BackButton = styled.div`
  position: absolute;
  left: -40px;
  top: 5px;
`;

export default function BallotMeasureDetailPage(): JSX.Element {
  const history = useHistory();

  interface RouteParams {
    measureId: string;
  }

  const { measureId } = useParams<RouteParams>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const { status, data: measure, error } = useMeasure(measureId);

  const measure = mockMeasures.find(
    (measure) => measure.id.toString() === measureId
  ) as BallotMeasureDetail;

  const issueTags = measure?.issueTags ?? [];
  const measureOriginType =
    measure?.measureOrigin && measure?.measureType
      ? `${measure?.measureOrigin} ${measure?.measureType}`
      : '';

  const passesWith = measure?.votingPassageRequirement ?? '';

  const chips = [...issueTags, measureOriginType, passesWith].sort();

  const argumentsRef = useRef(null);
  const moreInfoRef = useRef(null);
  const fiscalImpactsRef = useRef(null);

  if (measure == null) {
    return <Redirect to="/404" />; // measure not found
  }

  return (
    <>
      <MetaTags
        title={`${measure.ballotMeasureCode} - ${measure.shortTitle}`}
        description={measure.ballotTitle}
      />
      <MobileContainer>
        <MobileDetails>
          <MobileStickyButtons />
          <h2>{measure.ballotMeasureCode}</h2>
          <h1 className="measure-name">{measure.shortTitle}</h1>
          <ChipsContainer chips={chips}></ChipsContainer>
          <DetailsSection>
            <BulletList items={measure.definitions} />
          </DetailsSection>

          <DetailsSection>
            <h3>What Your Vote Means</h3>
            <BallotMeasureVoteMeaning measure={measure} />
          </DetailsSection>

          <DetailsSection>
            <h3>Official Language</h3>
            <p>{measure.ballotTitle}</p>
            <section className="centered">
              <a
                href={measure.fullTextUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SButton outlined>View Full Text</SButton>
              </a>
            </section>
          </DetailsSection>

          <DetailsSection>
            <h3>Arguments</h3>
            <BallotMeasureArguments measure={measure} />
          </DetailsSection>
          <DetailsSection>
            <h3>More Information</h3>
            <BallotMeasureMoreInfo infoItems={measure.additionalInformation} />
          </DetailsSection>
          <DetailsSection>
            <h3>Estimated Fiscal Impact</h3>
            <BallotMeasureMoreInfo infoItems={measure.fiscalImpacts} />
          </DetailsSection>
          <DetailsSection>
            <h3>Endorsements</h3>
            <BallotMeasureEndorsements measure={measure} />
          </DetailsSection>
        </MobileDetails>
      </MobileContainer>
      <DesktopContainer>
        <DetailsContainer>
          <BackButton>
            <ChevronLeft
              color={palette.white}
              onClick={() => history.push('/colorado')}
              style={{ cursor: 'pointer' }}
            />
          </BackButton>
          <FlagSection
            colorTheme="darkBlue"
            title="Colorado Statewide Ballot Measures"
          >
            <>
              <ShareButton type="desktop" />
              <h2>{measure.ballotMeasureCode}</h2>
              <h1 className="measure-name">{measure.shortTitle}</h1>

              <ChipsContainer chips={chips}></ChipsContainer>

              <DetailsSection>
                <BulletList items={measure.definitions} />
              </DetailsSection>

              <DetailsSection>
                <h3>What Your Vote Means</h3>
                <BallotMeasureVoteMeaning measure={measure} />
              </DetailsSection>

              <DetailsSection>
                <h3>Official Language</h3>
                <p className="narrow">{measure.ballotTitle}</p>
                <section className="centered">
                  <SButton outlined fullWidth>
                    <a
                      href={measure.fullTextUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Full Text
                    </a>
                  </SButton>
                </section>
              </DetailsSection>

              <DetailsSection ref={argumentsRef}>
                <h3>Arguments</h3>
                <BallotMeasureArguments
                  measure={measure}
                  scrollRef={argumentsRef}
                />
              </DetailsSection>

              <DetailsSection ref={moreInfoRef}>
                <h3>
                  More Information
                  <span>{measure.additionalInformation.length}</span>
                </h3>
                <BallotMeasureMoreInfo
                  infoItems={measure.additionalInformation}
                  scrollRef={moreInfoRef}
                />
              </DetailsSection>

              <DetailsSection ref={fiscalImpactsRef}>
                <h3>
                  Estimated Fiscal Impact
                  <span>{measure?.fiscalImpacts.length}</span>
                </h3>
                <BallotMeasureMoreInfo
                  infoItems={measure.fiscalImpacts}
                  scrollRef={fiscalImpactsRef}
                />
              </DetailsSection>

              {/* <DetailsSection>
                <h3>Endorsements</h3>
                <BallotMeasureEndorsements measure={measure} />
              </DetailsSection> */}
            </>
          </FlagSection>
        </DetailsContainer>
      </DesktopContainer>
    </>
  );
}
