export function randomSort<T>(arr: T[]): T[] {
  return arr.sort(() => 0.5 - Math.random());
}

export function shuffle<T>(array: T[]): T[] {
  const newArray = [];

  for (let i = 0; i < array.length - 1; i++) {
    // The following line removes one random element from array
    // and pushes it onto newArray
    newArray.push(array.splice(Math.floor(Math.random() * array.length), 1)[0]);
  }
  // Push the remaining item onto newArray
  newArray.push(array[0]);

  return newArray;
}

// // https://toreaurstad.blogspot.com/2019/12/enumerable-range-in-javascript.html
// export function* range(min: number, ct: number): Generator<number> {
//   for (let x = min; x <= min + ct; x++) {
//     yield x;
//   }
// }

// https://dev.to/namirsab/comment/2050
export const range = (start: number, end: number): number[] => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};
