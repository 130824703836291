import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { destroyUser } from 'Services';
import { Checkbox, SButton } from 'Components';
import { ProfileSection, FieldContainer } from './shared';

export function DestroyAccount(): JSX.Element {
  const [destroyEngaged, setDestroyEngaged] = useState(false);

  const history = useHistory();

  const handleDestroy = () =>
    window.confirm(
      'Are you absolutely sure you want to destroy your account and all of its data?'
    ) &&
    destroyUser().then((res) => {
      if (res.ok) {
        history.push('/');
      }
    });

  return (
    <ProfileSection>
      <h1>Danger zone</h1>
      <FieldContainer>
        <div style={{ textAlign: 'left', marginBottom: '1rem' }}>
          <p>All of your data will be destroyed and cannot be recovered.</p>
          <Checkbox
            label="I am sure I want to completely delete my account."
            checked={destroyEngaged}
            onChange={() => setDestroyEngaged(!destroyEngaged)}
          />
        </div>
        <SButton
          type="submit"
          outlined
          fullWidth
          disabled={!destroyEngaged}
          onClick={() => handleDestroy()}
        >
          Delete
        </SButton>
      </FieldContainer>
    </ProfileSection>
  );
}
