import styled, { css, useTheme } from 'styled-components';
import { palette } from 'Context';
import { transparentize } from 'color2k';

export const MobileContainer = styled.div`
  position: relative;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

export const DesktopContainer = styled.div`
  position: relative;
  width: 100%;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

interface BoxContainerProps {
  background?: string;
  margin?: string | 0;
  padding?: string | 0;
  width?: string;
  outline?: boolean;
  center?: boolean;
}

export const EmptyContainer = styled.div``;

export const BoxContainer = styled.div<BoxContainerProps>((props) => {
  const { color, font } = useTheme();

  const {
    background = color.background.secondary,
    margin = 0,
    padding = '1rem',
    width = 'auto',
    outline = false,
    center = false,
  } = props;

  return css`
    width: ${width};
    font-family: ${font.family.proximaNova};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    margin: ${margin};
    padding: ${padding};
    background: ${background};
    border: 1px solid
      ${outline ? transparentize(color.foreground.primary, 0.8) : 'none'};
    display: flex;
    flex-direction: column;

    ${center &&
    css`
      justify-content: center;
      align-items: center;
    `}
  `;
});

interface OrderContainerProps {
  order: number;
}

export const OrderContainer = styled.div<OrderContainerProps>(({ order }) => ({
  order,
}));

interface OutlineContainerProps {
  borderColor?: string;
  flexDirection?: 'row' | 'column';
  padding?: string;
}

export const OutlineContainer = styled.div<OutlineContainerProps>(
  ({
    borderColor = 'transparent',
    flexDirection = 'column',
    padding = '1.25rem',
  }) => {
    return css`
      ${borderColor &&
      css`
        border: 1px solid ${borderColor};
      `}

      box-sizing: border-box;
      border-radius: 30px;
      padding: 1.25rem;
      display: inline-flex;
      flex-direction: ${flexDirection};
      justify-content: center;
      text-align: center;

      > * {
        padding: ${padding};
      }
    `;
  }
);

export interface GapContainerProps {
  gap?: string | 0;
  direction?: 'row' | 'column';
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  fill?: boolean;
  fullWidth?: boolean;
}

export const GapContainer = styled.div<GapContainerProps>((props) => {
  const {
    gap = 0,
    direction = 'row',
    wrap = 'nowrap',
    fill = true,
    fullWidth = false,
  } = props;

  return css`
    flex-direction: ${direction};
    display: ${fullWidth ? 'flex' : 'inline-flex'};

    flex-wrap: ${wrap};

    margin: calc(-1 * ${gap}) 0 0 calc(-1 * ${gap});
    ${fill
      ? css`
          width: calc(100% + ${gap});
        `
      : ''}

    > * {
      margin: ${gap} 0 0 ${gap};
      ${fullWidth &&
      css`
        flex: 1;
      `}
    }
  `;
});

interface DividerContainerProps {
  dividerColor?: string;
  direction?: 'row' | 'column';
  padding?: string | 0;
}

export const DividerContainer = styled.div<DividerContainerProps>((props) => {
  const { color } = useTheme();

  const {
    dividerColor = color.foreground.primary,
    direction = 'column',
    padding = '1.5rem',
  } = props;

  const isRow = direction === 'row';

  const border = `1px solid ${transparentize(dividerColor, 0.8)}`;

  return css`
    display: flex;
    flex-direction: ${direction};
    ${isRow
      ? css`
          width: 100%;
        `
      : css`
          height: 100%;
        `}
    background: ${color.background.secondary};
    border-radius: 30px;
    border: ${border};
    padding: ${padding};

    > * {
      border-bottom: ${isRow ? 'none' : border};
      border-right: ${isRow ? border : 'none'};
      padding: ${padding};
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > :first-child {
      padding-top: 0;
    }

    > :last-child {
      border-bottom: none;
      border-right: none;
      padding-bottom: 0;
    }
  `;
});

/** A row with the first and last element aligned to the ends of the row, and all other elements centered */
export const AlignedRow = styled.div`
  display: flex;

  > * {
    flex: 1 1 auto;
  }

  > :first-child {
    flex: 1 0 50%;
  }

  > :last-child {
    flex: 1 1 50%;
    justify-content: flex-end;
  }
`;

interface RowProps {
  margin?: string | 0;
}
export const Row = styled.div<RowProps>(({ margin = 0 }) => {
  return css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: ${margin};
  `;
});

export const Flippity = styled(AlignedRow)`
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

interface VerticalLineProps {
  marginRight?: string | 0;
  marginLeft?: string | 0;
}

export const VerticalLine = styled.div<VerticalLineProps>((props) => {
  const {
    theme: { color },
    marginLeft = 0,
    marginRight = 0,
  } = props;
  return css`
    margin-left: ${marginLeft};
    margin-right: ${marginRight};
    border-right: 1px solid ${color.foreground.primary};
    align-self: stretch;
    width: 1px;
  `;
});

export const Card = styled.div(() => {
  const { spacing, font } = useTheme();

  return css`
    height: 10rem;
    background-color: #00354d;
    font-family: ${font.family.proximaNova};
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    position: relative;
    align-content: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: ${spacing.sm};
    border: 1px solid ${palette.blueDark};
    &:hover {
      border: 1px solid ${palette.blue};
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    }
  `;
});

export const IconStack = styled.span`
  display: grid;
  svg {
    grid-area: 1 / 1;
  }
`;

export const MarkdownContainer = styled.div`
  body {
    font-size: 14px;
    line-height: 1.6;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    padding: 30px;
    color: #333;
  }

  body > *:first-child {
    margin-top: 0 !important;
  }

  body > *:last-child {
    margin-bottom: 0 !important;
  }

  a {
    color: #4183c4;
    text-decoration: none;
  }

  a.absent {
    color: #cc0000;
  }

  a.anchor {
    display: block;
    padding-left: 30px;
    margin-left: -30px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px 0 10px;
    padding: 0;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    cursor: text;
    position: relative;
  }

  h2:first-child,
  h1:first-child,
  h1:first-child + h2,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  h1:hover a.anchor,
  h2:hover a.anchor,
  h3:hover a.anchor,
  h4:hover a.anchor,
  h5:hover a.anchor,
  h6:hover a.anchor {
    text-decoration: none;
  }

  h1 tt,
  h1 code {
    font-size: inherit;
  }

  h2 tt,
  h2 code {
    font-size: inherit;
  }

  h3 tt,
  h3 code {
    font-size: inherit;
  }

  h4 tt,
  h4 code {
    font-size: inherit;
  }

  h5 tt,
  h5 code {
    font-size: inherit;
  }

  h6 tt,
  h6 code {
    font-size: inherit;
  }

  h1 {
    font-size: 28px;
    color: black;
  }

  h2 {
    font-size: 24px;
    border-bottom: 1px solid #cccccc;
    color: black;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    color: #777777;
    font-size: 14px;
  }

  p,
  blockquote,
  ul,
  ol,
  dl,
  li,
  table,
  pre {
    margin: 15px 0;
  }

  hr {
    border: 0 none;
    color: #cccccc;
    height: 4px;
    padding: 0;
  }

  body > h2:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  body > h1:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  body > h1:first-child + h2 {
    margin-top: 0;
    padding-top: 0;
  }

  body > h3:first-child,
  body > h4:first-child,
  body > h5:first-child,
  body > h6:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  a:first-child h1,
  a:first-child h2,
  a:first-child h3,
  a:first-child h4,
  a:first-child h5,
  a:first-child h6 {
    margin-top: 0;
    padding-top: 0;
  }

  h1 p,
  h2 p,
  h3 p,
  h4 p,
  h5 p,
  h6 p {
    margin-top: 0;
  }

  li p.first {
    display: inline-block;
  }

  li p::before {
    content: '•';
    color: ${palette.blue};
    display: inline-block;
    width: 0.8em;
    margin-left: -0.8em;
    margin-top: -0.2em;
    font-size: 1.5em;
    vertical-align: middle;
  }

  ul {
    list-style: none;
  }

  ul,
  ol {
    padding-left: 30px;
  }

  ul :first-child,
  ol :first-child {
    margin-top: 0;
  }

  ul :last-child,
  ol :last-child {
    margin-bottom: 0;
  }

  dl {
    padding: 0;
  }

  dl dt {
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    padding: 0;
    margin: 15px 0 5px;
  }

  dl dt:first-child {
    padding: 0;
  }

  dl dt > :first-child {
    margin-top: 0;
  }

  dl dt > :last-child {
    margin-bottom: 0;
  }

  dl dd {
    margin: 0 0 15px;
    padding: 0 15px;
  }

  dl dd > :first-child {
    margin-top: 0;
  }

  dl dd > :last-child {
    margin-bottom: 0;
  }

  blockquote {
    border-left: 4px solid #dddddd;
    padding: 0 15px;
    color: #777777;
  }

  blockquote > :first-child {
    margin-top: 0;
  }

  blockquote > :last-child {
    margin-bottom: 0;
  }

  table {
    padding: 0;
  }
  table tr {
    border-top: 1px solid #cccccc;
    background-color: white;
    margin: 0;
    padding: 0;
  }

  table tr:nth-child(2n) {
    background-color: #f8f8f8;
  }

  table tr th {
    font-weight: bold;
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
  }

  table tr td {
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
  }

  table tr th :first-child,
  table tr td :first-child {
    margin-top: 0;
  }

  table tr th :last-child,
  table tr td :last-child {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
  }

  span.frame {
    display: block;
    overflow: hidden;
  }

  span.frame > span {
    border: 1px solid #dddddd;
    display: block;
    float: left;
    overflow: hidden;
    margin: 13px 0 0;
    padding: 7px;
    width: auto;
  }

  span.frame span img {
    display: block;
    float: left;
  }

  span.frame span span {
    clear: both;
    color: #333333;
    display: block;
    padding: 5px 0 0;
  }

  span.align-center {
    display: block;
    overflow: hidden;
    clear: both;
  }

  span.align-center > span {
    display: block;
    overflow: hidden;
    margin: 13px auto 0;
    text-align: center;
  }

  span.align-center span img {
    margin: 0 auto;
    text-align: center;
  }

  span.align-right {
    display: block;
    overflow: hidden;
    clear: both;
  }

  span.align-right > span {
    display: block;
    overflow: hidden;
    margin: 13px 0 0;
    text-align: right;
  }

  span.align-right span img {
    margin: 0;
    text-align: right;
  }

  span.float-left {
    display: block;
    margin-right: 13px;
    overflow: hidden;
    float: left;
  }

  span.float-left span {
    margin: 13px 0 0;
  }

  span.float-right {
    display: block;
    margin-left: 13px;
    overflow: hidden;
    float: right;
  }

  span.float-right > span {
    display: block;
    overflow: hidden;
    margin: 13px auto 0;
    text-align: right;
  }

  code,
  tt {
    margin: 0 2px;
    padding: 0 5px;
    white-space: nowrap;
    border: 1px solid #eaeaea;
    background-color: #f8f8f8;
    border-radius: 3px;
  }

  pre code {
    margin: 0;
    padding: 0;
    white-space: pre;
    border: none;
    background: transparent;
  }

  .highlight pre {
    background-color: #f8f8f8;
    border: 1px solid #cccccc;
    font-size: 13px;
    line-height: 19px;
    overflow: auto;
    padding: 6px 10px;
    border-radius: 3px;
  }

  pre {
    background-color: #f8f8f8;
    border: 1px solid #cccccc;
    font-size: 13px;
    line-height: 19px;
    overflow: auto;
    padding: 6px 10px;
    border-radius: 3px;
  }

  pre code,
  pre tt {
    background-color: transparent;
    border: none;
  }
`;
