import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { FlagSection } from 'Components';

const DateHeading = styled.div(({ theme: { font } }) => {
  return css`
    font-weight: ${font.weights.semiBold};
    font-family: ${font.family.commutersSans};
    font-size: 55px;
    line-height: 69px;
    margin-bottom: 30px;
  `;
});

const Title = styled.div`
  font-family: ${({ theme: { font } }) => font.family.commutersSans};
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 20px;
`;

const Description = styled.div(({ theme: { font } }) => {
  return css`
    font-family: ${font.family.proximaNova};
    font-weight: ${font.weights.light};
    font-size: 20px;
    line-height: 32px;
    width: 80%;
  `;
});

interface UpcomingVoteDetailsProps {
  date?: string;
  title?: string;
  description?: string;
}

export function UpcomingVoteDetails({
  date,
  title,
  description,
}: UpcomingVoteDetailsProps): JSX.Element {
  return (
    <>
      <FlagSection colorTheme="darkBlue" title="Upcoming Vote">
        <DateHeading>{moment(date).format('MMMM D YYYY')}</DateHeading>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </FlagSection>
    </>
  );
}
