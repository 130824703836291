import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import {
  PartyAvatar,
  MediaLinkProps,
  MediaLinks,
  BlueDivider,
  Flippity,
  VerticalLine,
  MobileContainer,
  DesktopContainer,
} from 'Components';

import { Party, getTheme } from 'Context';

import {
  BlueText,
  Heading,
  SubHeading,
  BasicInfo,
  BasicInfoProps,
  CommitteeProps,
  Committees,
  NextElection,
  NextElectionProps,
} from '.';

const Wrapper = styled.div`
  padding: 0;
`;

const Description = styled.div(({ theme: { color, font } }) => {
  return css`
    padding: 0;
    font-family: ${font.family.proximaNova};
    font-weight: ${font.weights.light};
    font-size: 1.25rem;
    line-height: 1.6;
    color: ${color.text.primary};
    @media only screen and (max-width: 1023px) {
      font-weight: 500;
      margin: 0 1.5rem;
    }
  `;
});

export interface PoliticianProps {
  id: string | number;
  name: string;
  src: string;
  description?: string;
  mediaLinks?: MediaLinkProps[];
  party?: Party;
  position?: string;
  location?: string;
  nextElection?: NextElectionProps;
  basicInfo?: BasicInfoProps;
  committees?: CommitteeProps[];
  isIncumbent?: boolean;
}

const GeneralWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Info = styled.div`
  margin-left: 2rem;
  @media only screen and (max-width: 1280px) {
    margin: 0;
  }
`;

const PoliticianRoleWrapper = styled.div`
  text-align: center;
  width: 100%;
  @media only screen and (max-width: 1023px) {
    margin-bottom: 1rem;
  }
`;

interface PoliticianRoleProps {
  position: string;
  location: string;
}

export function PoliticianRole(props: PoliticianRoleProps): JSX.Element {
  const { position, location } = props;

  return (
    <PoliticianRoleWrapper>
      <BlueDivider />
      <SubHeading>{position}</SubHeading>
      <BlueText>{location}</BlueText>
    </PoliticianRoleWrapper>
  );
}

export function Politician(props: PoliticianProps): JSX.Element {
  const {
    name,
    src,
    description,
    mediaLinks = [],
    party = 'other',
    position,
    location,
    nextElection,
    basicInfo,
    committees,
  } = props;

  return (
    <Wrapper>
      <GeneralWrapper>
        <PartyAvatar
          name={name}
          src={src}
          size="13rem"
          badgeSize="3rem"
          badgeFontSize="2rem"
          round={true}
          party={party}
        />

        <MobileContainer>
          {position && location && (
            <PoliticianRole {...{ position, location }} />
          )}
        </MobileContainer>

        <Info>
          <DesktopContainer>
            <Heading>{name}</Heading>
          </DesktopContainer>

          <Description>{description}</Description>
        </Info>

        <MediaLinks mediaLinks={mediaLinks} />
      </GeneralWrapper>
      <MobileContainer>
        <BlueDivider style={{ marginTop: 0 }} />
      </MobileContainer>

      <DesktopContainer>
        {position && location && <PoliticianRole {...{ position, location }} />}
        <BlueDivider />
      </DesktopContainer>

      {nextElection && (
        <>
          <NextElection {...nextElection} />
          <BlueDivider />
        </>
      )}

      <Flippity>
        {basicInfo && basicInfo.yearsInOffice > 0 && (
          <>
            <BasicInfo {...basicInfo} />
          </>
        )}
        <ThemeProvider theme={getTheme('darkBlue')}>
          {committees && committees.length > 0 && (
            <>
              {basicInfo && (
                <>
                  <VerticalLine marginLeft="2rem" marginRight="2rem" />
                  <MobileContainer>
                    <BlueDivider />
                  </MobileContainer>
                </>
              )}
              <Committees committees={committees} />
            </>
          )}
        </ThemeProvider>
      </Flippity>
      {((basicInfo && basicInfo.yearsInOffice) ||
        (committees && committees.length > 0)) && <BlueDivider />}
    </Wrapper>
  );
}
