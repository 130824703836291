import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { palette } from 'Context';
import { scrollToTop } from 'Utils';

const NavBar = styled.div`
  grid-area: contextNav;
  position: sticky;
  bottom: 0;
  background: ${palette.blueDarkest};
  border-bottom: 1px solid ${palette.blue};
  margin-bottom: 1rem;
`;

const NavItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 1rem;
  @media only screen and (min-width: 768px) {
    width: 60vh;
    margin: 0 3rem;
  }
`;

interface NavItemProps {
  isActive?: boolean;
}

const NavItem = styled.h2<NavItemProps>`
  font-size: 20px;
  width: 30vw;
  text-align: center;
  cursor: pointer;
  margin: 0;
  padding: 1rem 0;
  border-bottom: ${(props) => (props.isActive ? '2px solid orange' : '')};
`;

interface ContextNavProps {
  setQuery: (query: string) => void;
}

export default function ContextNav(props: ContextNavProps): JSX.Element {
  const contextTypes = useMemo(
    () => ({
      local: 'LOCAL',
      state: 'STATE',
      federal: 'FEDERAL',
    }),
    []
  );

  const [contextSelected, setContextSelected] = useState(contextTypes.local);

  const [city, setCity] = useState('Denver');

  const { setQuery } = props;

  useEffect(() => {
    const reverseGeocode = () => {
      try {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`;
          const response = await fetch(url);

          if (!response.ok) {
            throw new Error(
              'Something went wrong fetching from the Big Data Cloud Api'
            );
          }

          if (response.ok) {
            const json = await response.json();
            setCity(json.city);
          }
        });
      } catch (error) {
        throw new Error((error as Error).message);
      }
    };
    reverseGeocode();
  }, []);

  useEffect(() => {
    switch (contextSelected) {
      case contextTypes.local:
        setQuery(`everything?q=${city}&`);
        break;
      case contextTypes.state:
        setQuery('everything?q=colorado&');
        break;
      case contextTypes.federal:
        setQuery('everything?q=politics&');
        break;
    }
    scrollToTop();
  }, [city, contextSelected, contextTypes, setQuery]);
  return (
    <NavBar>
      <NavItems>
        <NavItem
          isActive={contextSelected === contextTypes.local}
          onClick={() => setContextSelected(contextTypes.local)}
        >
          LOCAL
        </NavItem>
        <NavItem
          isActive={contextSelected === contextTypes.state}
          onClick={() => setContextSelected(contextTypes.state)}
        >
          STATE
        </NavItem>
        <NavItem
          isActive={contextSelected === contextTypes.federal}
          onClick={() => setContextSelected(contextTypes.federal)}
        >
          FEDERAL
        </NavItem>
      </NavItems>
    </NavBar>
  );
}

ContextNav.propTypes = {};
