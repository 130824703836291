import styled from 'styled-components';

export const CompareContainer = styled.ul`
  display: flex;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  padding: 0;

  article:first-child {
    margin-right: 2em;
  }

  @media only screen and (max-width: 1023px) {
    flex-direction: column;

    article:first-child {
      margin-right: 0;
      margin-bottom: 2em;
    }
  }
`;
