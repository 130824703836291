import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { Card } from 'Components';
import { palette, themes } from 'Context';

export interface InitiativeProps {
  id?: string | number;
  name: string;
  issueTags: Array<string>;
  code?: string;
  votesFor?: number;
  votesAgainst?: number;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  font-size: 20px;
  margin: -1.1rem 0.25rem;
  padding: 0px 10px;
`;

const Title = styled.span`
  color: white;
  font-weight: ${({ theme: { font } }) => font.weights.semiBold};
  line-height: 1.8rem;
`;

const Bubble = styled.span(({ theme }) => {
  const { color, font } = theme;

  return css`
    font-family: ${font.family.proximaNova};
    background-color: ${color.greyLightest};
    border-radius: 50px;
    font-weight: normal;
    font-size: 13px;
    color: ${color.text.primary};
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    :focus {
      outline: none;
    }
    border: none;
    text-align: center;
  `;
});

export const BlueBubble = styled(Bubble)(({ theme }) => {
  const { spacing } = theme;
  return css`
    background-color: ${palette.blueDarker};
    color: white;
    padding: ${spacing.xs} ${spacing.sm};
    border: 1px solid ${palette.blueDark};
  `;
});

// const VotesFor = styled(Bubble)`
//   color: ${({ theme: { color } }) => color.success};
//   font-size: 15px;
// `;

// const VotesAgainst = styled(Bubble)`
//   color: ${({ theme: { color } }) => color.error};
//   font-size: 15px;
// `;

// const VoteCount = styled.span(({ theme }) => {
//   const { font, color, spacing } = theme;
//   return {
//     fontFamily: font.family.proximaNova,
//     color: color.black,
//     fontWeight: font.weights.bold,
//     marginLeft: spacing.xs,
//     marginRight: spacing.sm,
//   };
// });

const CardWrapper = styled.button(({ theme }) => {
  const { spacing } = theme;
  return {
    background: `none`,
    cursor: `pointer`,
    marginBottom: spacing.md,
    border: `none`,
    width: `100%`,
    height: '100%',
  };
});

export function Initiative(props: InitiativeProps): JSX.Element {
  const { name, issueTags, code, onClick } = props;
  return (
    <ThemeProvider theme={themes.form}>
      {/* Passthrough button wrapper. Clickable divs are an accessibility no-no */}
      <CardWrapper onClick={onClick}>
        <Card>
          <TopSection>
            <BlueBubble>{code}</BlueBubble>
            <BlueBubble>{issueTags[0]}</BlueBubble>
          </TopSection>
          <TitleSection>
            <Title>{name}</Title>
          </TitleSection>
        </Card>
      </CardWrapper>
    </ThemeProvider>
  );
}
