import React from 'react';
import { useForm } from 'react-hook-form';
import { PasswordParams } from 'Context';
import { SButton, TextInput } from 'Components';

import { ProfileSection, FieldContainer } from './shared';

interface ProfileForms {
  handlePasswordSave: (passwordData: PasswordParams) => void;
}

export function ChangePassword({
  handlePasswordSave,
}: ProfileForms): JSX.Element {
  const {
    handleSubmit: handlePasswordSubmit,
    register,
    formState,
    getValues,
  } = useForm<PasswordParams>({ mode: 'onChange' });

  const { errors } = formState;
  const { confirmPassword, password } = getValues();

  return (
    <>
      <ProfileSection>
        <h1>Password</h1>
        <form onSubmit={handlePasswordSubmit(handlePasswordSave)}>
          <FieldContainer>
            <TextInput
              {...register('oldPassword', { required: true })}
              type="password"
              error={errors.oldPassword}
              label="Current Password"
            />
            <TextInput
              {...register('password', {
                required: true,
                minLength: { value: 8, message: '8 characters minimum' },
                validate: (value) =>
                  value === confirmPassword || 'Passwords must match',
              })}
              type="password"
              error={errors.confirmPassword}
              label="New Password"
            />
            <TextInput
              {...register('confirmPassword', {
                required: true,
                minLength: { value: 8, message: '8 characters minimum' },
                validate: (value) =>
                  value === password || 'Passwords must match',
              })}
              type="password"
              error={errors.confirmPassword}
              label="Confirm New Password"
            />
            <SButton
              type="submit"
              outlined
              fullWidth
              disabled={!formState.isDirty || !formState.isValid}
            >
              Save
            </SButton>
          </FieldContainer>
        </form>
      </ProfileSection>
    </>
  );
}
