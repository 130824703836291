import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import styled from 'styled-components';
import Modal from 'styled-react-modal';

import {
  LandingPage,
  HomePage,
  PoliticianPage,
  NewsPage,
  BallotMeasureDetailPage,
  BallotMeasurePage,
  ScrollToTop,
  EmailVerifyPage,
  MainNav,
  AppLayout,
  NotFound404,
  AuthFlow,
  AuthFlowRoutes,
  BallotPage,
  UserProfilePage,
} from 'Components';

import { useAuth } from 'Context';

const PageWrapper = styled.div`
  padding: 1rem;
  overflow: auto;
  grid-area: content;
  padding: 0;
  @media only screen and (min-width: 1024px) {
    grid-area: contextNav / contextNav / content;
    max-width: 76rem;
    padding: 5rem;
  }
`;

const NavlessPageWrapper = styled.div`
  padding: 0.5rem;
  @media only screen and (min-width: 1024px) {
    padding: 6rem;
    grid-area: content;
    max-width: 1200px;
    margin: auto;
  }
`;

const AuthModal = Modal.styled`
  max-width: 600px;

  @media only screen and (min-width: 1024px) {
    width: 75%;
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`;

export default function Routes(): JSX.Element {
  const { authModalState, setAuthModalState } = useAuth();
  return (
    <Router>
      <AuthModal
        isOpen={!!authModalState}
        onBackgroundClick={() => setAuthModalState(null)}
        onEscapeKeydown={() => setAuthModalState(null)}
      >
        <AuthFlow onCancel={() => setAuthModalState(null)} />
      </AuthModal>
      <Switch>
        <Route exact path="/">
          <Redirect to="/colorado" />
        </Route>
        <Route>
          <AppLayout>
            <MainNav />
            <Switch>
              <Route exact path="/home">
                <PageWrapper>
                  <HomePage />
                </PageWrapper>
              </Route>
              {/* <Route exact path="/ballot">
                <PageWrapper>
                  <BallotPage />
                </PageWrapper>
              </Route> */}
              <Route exact path="/colorado">
                <ScrollToTop />
                <PageWrapper>
                  <BallotMeasurePage />
                </PageWrapper>
              </Route>
              <Route path="/colorado/:measureId">
                <PageWrapper id="detail-page">
                  <BallotMeasureDetailPage />
                </PageWrapper>
              </Route>
              <Route path="/politician/:politicianId">
                <PageWrapper>
                  <PoliticianPage />
                </PageWrapper>
              </Route>
              <Route path="/news">
                <NewsPage />
              </Route>
              <Route path="/user/verify/:id/:token">
                <PageWrapper>
                  <EmailVerifyPage />
                </PageWrapper>
              </Route>
              <Route exact path="/settings/profile">
                <PageWrapper>
                  <UserProfilePage />
                </PageWrapper>
              </Route>

              <NavlessPageWrapper>
                <Route path="/login" component={AuthFlowRoutes} />
                <Route path="/register" component={AuthFlowRoutes} />
                <Route path="/guest" component={AuthFlowRoutes} />

                <Route path="/404" component={NotFound404} />
              </NavlessPageWrapper>

              <Route path="/success">
                <Redirect to="/ballot" />
              </Route>

              <Redirect to="/404" />
            </Switch>
          </AppLayout>
        </Route>
      </Switch>
    </Router>
  );
}
