import React from 'react';
import styled, { css } from 'styled-components';
import { FaGlobe, FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

const linkIcons = {
  web: FaGlobe,
  twitter: FaTwitter,
  instagram: FaInstagram,
  facebook: FaFacebook,
  other: FaGlobe,
};

export type MediaLinkType = 'web' | 'twitter' | 'facebook' | 'instagram';

export interface MediaLinkProps {
  type: MediaLinkType;
  url: string;
}

const IconWrapper = styled.a(({ theme: { color } }) => {
  return css`
    padding: 0 0.5rem;
    color: ${color.foreground.primary};
    :hover {
      color: ${color.foreground.secondary};
    }
    @media only screen and (max-width: 1023px) {
      padding: 0 1rem;
    }
  `;
});

function MediaLink({ type, url }: MediaLinkProps) {
  const Icon = type in linkIcons ? linkIcons[type] : FaGlobe;
  return (
    <IconWrapper href={url} target="blank" rel="noopener noreferrer">
      <Icon size="2rem" />
    </IconWrapper>
  );
}

const OuterWrapper = styled.div`
  align-self: center;
  @media only screen and (max-width: 1280px) {
    margin: 1rem 0 0 0;
  }
  @media only screen and (max-width: 1023px) {
    margin: 2rem 0;
  }
`;

const InnerWrapper = styled.div(({ theme: { color } }) => ({
  display: 'flex',
  flexDirection: 'row',
  color: color.foreground.primary,
}));

export interface MediaLinksProps {
  mediaLinks: MediaLinkProps[];
}

export function MediaLinks({ mediaLinks }: MediaLinksProps): JSX.Element {
  return (
    <OuterWrapper>
      <InnerWrapper>
        {mediaLinks.map((mediaLink, i) => (
          <MediaLink key={mediaLink.type + i} {...mediaLink} />
        ))}
      </InnerWrapper>
    </OuterWrapper>
  );
}
