import styled, { css } from 'styled-components';
import { palette } from 'Context';

export const HeaderFooterBox = styled.section(({ theme: { color } }) => {
  return css`
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: ${color.background.secondary};
    padding: 2rem;
    border-radius: 2rem;
    border: 1px solid ${palette.blueDark};
    margin: auto;
    margin-bottom: 2rem;
    max-width: 990px;

    @media only screen and (max-width: 1023px) {
      padding: 1.5rem 1rem 2rem;
      border-radius: 1rem;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    h5 {
      text-align: left;
      line-height: 2rem;
    }

    header,
    footer {
      text-align: left;
      width: 100%;
      border-bottom: 1px solid ${palette.blueDark};
      height: 3rem;
      padding-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img,
      i {
        width: 3rem;
        font-size: 3rem;
        border-radius: 3rem;
      }

      h4 {
        flex: 1 1 100%;
        font-size: 1.25rem;
        margin-left: 1rem;
      }
    }

    header:last-child,
    footer:last-child {
      padding-bottom: 0;
      padding-top: 1rem;
      border-bottom: none;
      border-top: 1px solid ${palette.blueDark};
      margin-bottom: -0.6rem;
      margin-top: 1rem;
    }

    header:first-child {
      margin-top: -0.6rem;
      margin-bottom: 0;
    }

    h5 {
      font-size: 1.5rem;
      margin: 0;
    }
  `;
});
