import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { GapContainer } from 'Components';

export const Pill = styled.span(() => {
  const { color, font } = useTheme();
  return css`
    font-family: ${font.family.proximaNova};
    background-color: ${color.palette.blueDarkest};
    border: 1px solid ${color.palette.blueDark};
    border-radius: 50px;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
});

interface PillListProps {
  topics: string[];
}

export function PillList({ topics }: PillListProps): JSX.Element {
  return (
    <GapContainer gap=".25rem" wrap="wrap">
      {topics.map((topic, index) => (
        <Pill key={`topic-${topic}-${index}`}>{topic}</Pill>
      ))}
    </GapContainer>
  );
}
