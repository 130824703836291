import React, { useCallback } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { usePagination } from 'Hooks';

import {
  TitleSection,
  GapContainer,
  DividerContainer,
  BoxContainer,
  Em,
  Row,
  Txt,
  UpDownVotes,
  UpDownVotesProps,
  UpDownVoteDemo,
  UpDownVoteArea,
  SectionHeader,
  MobileContainer,
  DesktopContainer,
} from 'Components';

import { PillList } from './shared';

export interface OrgRatingProps {
  id: string;
  name: string;
  topics: string[];
  year: string;
  approvalRate: number;
  upDownVotes?: UpDownVotesProps;
}

export interface RatingsProps {
  ratings: OrgRatingProps[];
}

interface ApprovalRateProps {
  rate: number;
}

const ApprovalRate = styled.span.attrs<ApprovalRateProps>(({ rate }) => ({
  children: <>{rate}%</>,
}))<ApprovalRateProps>(({ rate }) => {
  const { color, font } = useTheme();

  const textColor = (() => {
    if (rate > 67) return color.success;
    else if (rate > 33) return color.info;
    else return color.error;
  })();

  return css`
    font-family: ${font.family.commutersSans};
    color: ${textColor};
    font-size: 1.375rem;
    margin-right: 1.5rem;

    @media only screen and (max-width: 1023px) {
      margin: 0.5rem auto;
      font-size: 2.5rem;
      text-align: center;
      width: 100%;
      display: block;
    }
  `;
});

const OrgName = styled.span(({ theme: { color } }) => {
  return css`
    font-weight: 600;
    border-bottom: 1px dotted ${color.text.secondary};
  `;
});

const OrgNameWrapper = styled.div`
  text-align: center;
  font-size: 1.125rem;
  padding-bottom: 0.25rem;
`;

function OrgRating(props: OrgRatingProps) {
  const { name, topics, year, approvalRate, upDownVotes } = props;

  const $upDownVoteArea = upDownVotes ? (
    <UpDownVotes {...upDownVotes} />
  ) : (
    <UpDownVoteDemo />
  );

  return (
    <div>
      <DesktopContainer>
        <Row>
          <Txt>
            {name}
            <Em style={{ marginLeft: '1rem' }}>
              {topics.map((topic) => `${topic} `)} - {year}
            </Em>
          </Txt>

          <Row>
            <ApprovalRate rate={approvalRate} />
            {$upDownVoteArea}
          </Row>
        </Row>
      </DesktopContainer>

      <MobileContainer>
        <PillList topics={topics} />

        <ApprovalRate rate={approvalRate} />
        <OrgNameWrapper>
          <Em>Rating by</Em> <OrgName>{name}</OrgName>.
        </OrgNameWrapper>
        <UpDownVoteArea>{$upDownVoteArea}</UpDownVoteArea>
      </MobileContainer>
    </div>
  );
}

function OrgRatings({ ratings }: RatingsProps): JSX.Element {
  return (
    <>
      {ratings.map((item, index) => (
        <BoxContainer key={`rating-${index}-${item.id}`}>
          <OrgRating {...item} />
        </BoxContainer>
      ))}
    </>
  );
}

export function Ratings({ ratings }: RatingsProps): JSX.Element {
  const { currentData, /*prevData, nextData,*/ sortData, ...paginationProps } =
    usePagination<OrgRatingProps>(ratings, 10, 5, 1, true);

  const { nextPage, prevPage } = paginationProps;

  const sortByHighest = useCallback(() => {
    sortData((a, b) => (a.approvalRate < b.approvalRate ? 1 : -1));
  }, [sortData]);

  const sortByLowest = useCallback(() => {
    sortData((a, b) => (a.approvalRate > b.approvalRate ? 1 : -1));
  }, [sortData]);

  // const prevPageData = useMemo(prevData, [prevData]);
  // const nextPageData = useMemo(nextData, [nextData]);

  // const sortByMostVotes = () => {
  //   sortData((a, b) =>
  //     (a.upDownVotes?.upVotes || 0) > (b.upDownVotes?.upVotes || 0) ? 1 : -1
  //   );
  // };

  const $header = (
    <SectionHeader
      paginationProps={paginationProps}
      sortOptions={[
        {
          text: `Highest`,
          id: `highest`,
          callback: sortByHighest,
        },
        {
          text: `Lowest`,
          id: `lowest`,
          callback: sortByLowest,
        },
        // {
        //   text: `Most Votes`,
        //   id: `mostVotes`,
        //   callback: sortByMostVotes,
        // },
      ]}
    />
  );

  return (
    <TitleSection
      {...{ nextPage, prevPage }}
      title="Organization Ratings"
      colorTheme="blue"
      header={$header}
    >
      <DesktopContainer>
        <GapContainer direction="column" gap="25px">
          <DividerContainer>
            {/* <Swiper
              onInit={(s) => {
                console.log('slides', s.slides);
                // if (prevPageData) s.slideTo(2);
              }}
              onSlideChange={(s) => {
                console.log('s.realIndex', s.realIndex);
              }}
            >
              {prevPageData && (
                <SwiperSlide>
                  <OrgRatings ratings={prevPageData} />
                </SwiperSlide>
              )}
              <SwiperSlide>
                <OrgRatings ratings={currentData()} />
              </SwiperSlide>
              {nextPageData && (
                <SwiperSlide>
                  <OrgRatings ratings={nextPageData} />
                </SwiperSlide>
              )}
            </Swiper> */}
            <OrgRatings ratings={currentData()} />
          </DividerContainer>
        </GapContainer>
      </DesktopContainer>

      <MobileContainer>
        <GapContainer direction="column" gap="25px">
          <OrgRatings ratings={currentData()} />
        </GapContainer>
      </MobileContainer>
    </TitleSection>
  );
}
