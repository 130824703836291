import React, { useState } from 'react';
import { FlagSection } from '../..';
import { verifyUser } from 'Services';
import { useParams } from 'react-router-dom';

export default function EmailVerifyPage(): JSX.Element {
  const [status, setStatus] = useState(`loading`);
  const { id, token } = useParams<{ id: string; token: string }>();

  verifyUser(id, token)
    .then((res) => {
      const result = res.data?.success ? `success` : `fail`;
      setStatus(result);
    })
    .catch(() => {
      setStatus(`fail`);
    });

  if (status === `success`) {
    return (
      <FlagSection colorTheme="darkBlue" title="Email Verified">
        <h1>Thank you for verifying your email.</h1>
        <p>
          Check out what&#39;s going on in your local government and community
          <a href="/ballot">here</a>.
        </p>
      </FlagSection>
    );
  } else if (status === `fail`) {
    return (
      <FlagSection colorTheme="darkBlue" title="Email Verification Failed">
        <h1>Your email address could not be verified.</h1>
        <p>
          Please make sure you are using the latest link that was sent to your
          email address.
        </p>
      </FlagSection>
    );
  }

  return (
    <FlagSection colorTheme="darkBlue" title="Verify Email">
      <h1>Verifying your email address...</h1>
      <p>Hold tight for a second.</p>
    </FlagSection>
  );
}
