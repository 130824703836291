import styled, { css, useTheme } from 'styled-components';

export const SHeading = styled.div(({ theme: { color, font } }) => {
  return css`
    font-family: ${font.family.proximaNova};
    font-size: 40px;
    line-height: 1.5;
    /* identical to box height */
    color: ${color.text.primary};
  `;
});

export const SSubHeading = styled.div(({ theme: { color, font } }) => {
  return css`
    font-family: ${font.family.proximaNova};
    font-size: 1rem;
    line-height: 1.5;
    color: ${color.text.primary};
  `;
});

interface NoteProps {
  error?: boolean;
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
}

export const SNote = styled.div<NoteProps>(({ error, textAlign = 'right' }) => {
  const { color, font } = useTheme();
  return css`
    font-family: ${font.family.proximaNova};
    font-style: italic;
    font-size: 12px;
    line-height: 12px;
    line-height: 21px;
    text-align: ${textAlign};

    color: ${error ? color.error : color.text.primary};
  `;
});

export const ErrorText = styled(SSubHeading)(
  ({ theme: { color } }) =>
    css`
      color: ${color.error};
    `
);

export const Em = styled.span(({ theme: { color } }) => ({
  color: color.text.secondary,
}));

interface TxtProps {
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: 'light' | 'normal' | 'semiBold' | 'bold';
  altColor?: 'error' | 'success' | 'info';
}

export const Txt = styled.div<TxtProps>(
  ({
    theme,
    fontSize = '18px',
    lineHeight = 1.5,
    fontWeight = 'normal',
    altColor,
  }) => {
    const { font, color } = theme;
    return css`
      font-family: ${font.family.proximaNova};
      font-size: ${fontSize};
      line-height: ${lineHeight || fontSize};
      font-weight: ${font.weights[fontWeight]};
      color: ${altColor ? color[altColor] : color.text.primary};
    `;
  }
);

export const SLink = styled.span(({ theme: { color, font } }) => {
  return css`
    font-family: ${font.family.proximaNova};
    font-size: 14px;
    line-height: 1.5;
    /* identical to box height */
    display: inline-block;
    color: ${color.foreground.primary};
    border-bottom: 1px dashed ${color.foreground.primary};
    cursor: pointer;
    padding: 5px;
    margin: auto;
  `;
});

export const SDivider = styled.hr(({ theme: { color } }) => {
  return css`
    margin: 20px 5px;
    border: none;
    border-bottom: 0.5px solid ${color.background.primary};
    opacity: 0.25;
  `;
});

export const BlueDivider = styled.hr(({ theme: { color } }) => {
  return css`
    margin: 1.25rem 0;
    padding: 0;
    border: none;
    border-bottom: 0.5px solid ${color.palette.blueDark};
  `;
});
