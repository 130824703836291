import React from 'react';
import styled from 'styled-components';

import {
  Politician,
  PoliticianProps,
  // Issues,
  GapContainer,
  Financials,
  FinancialsProps,
  Legislation,
  LegislationProps,
  OrgRatingProps,
  Ratings,
  Quotes,
  QuotesProps,
  EndorsementCardProps,
  Endorsements,
} from 'Components';

const Wrapper = styled.div`
  margin: 2rem;
  @media only screen and (max-width: 1023px) {
    margin: 0;
  }
`;

export interface PoliticianDetailProps {
  candidate: PoliticianProps;
  ratings?: OrgRatingProps[];
  financials?: FinancialsProps;
  legislation?: LegislationProps;
  quotes?: QuotesProps;
  endorsements?: EndorsementCardProps[];
}

export function PoliticianDetail(props: PoliticianDetailProps): JSX.Element {
  const { candidate, ratings, financials, legislation, endorsements, quotes } =
    props;

  return (
    <Wrapper>
      <GapContainer gap="35px" direction="column">
        <Politician {...candidate} />

        {endorsements && endorsements.length > 0 && (
          <Endorsements endorsements={endorsements} />
        )}

        {ratings && <Ratings ratings={ratings} />}

        {financials && <Financials {...financials} />}

        {legislation?.activity && legislation?.activity.length > 0 && (
          <Legislation {...legislation} />
        )}

        {quotes && <Quotes {...quotes} />}
      </GapContainer>
    </Wrapper>
  );
}
