import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ModalProvider } from 'styled-react-modal';
import { ThemeProvider } from 'styled-components';

import { theme } from './Context';
import AuthProvider from './AuthProvider';
import Routes from './Routes';
import { HelmetProvider } from 'react-helmet-async';

// Create a client
const queryClient = new QueryClient();

function App(): JSX.Element {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ModalProvider>
              <Routes />
            </ModalProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
