import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { FaCheckCircle, FaTimesCircle, FaCircle } from 'react-icons/fa';
import {
  TitleSection,
  GapContainer,
  Em,
  Txt,
  Row,
  UpDownVotes,
  UpDownVotesProps,
  UpDownVoteDemo,
  SectionHeader,
  DesktopContainer,
  MobileContainer,
  UpDownVoteArea,
} from 'Components';

import { usePagination } from 'Hooks';

import { PillList } from './shared';
import { BoxContainer } from 'Components/Atoms';

export interface LegislationItemProps {
  id: string;
  name: string;
  topics: string[];
  year?: string;
  action: string;
  upDownVotes?: UpDownVotesProps;
}

export interface LegislationProps {
  activity: LegislationItemProps[];
}

// interface LegislationActionProps {
//   action: string; // Should be 'Y' or 'N'
//   textOnly?: boolean;
// }

function useActionText(action: string) {
  const {
    color: { palette },
  } = useTheme();
  switch (action) {
    case 'Y':
      return { text: 'YES', color: palette.green, Icon: FaCheckCircle };
    case 'N':
      return { text: 'NO', color: palette.red, Icon: FaTimesCircle };
    case 'C':
      return { text: 'CO-SPONSOR', color: palette.green, Icon: FaCheckCircle };
    default:
      return { text: '', color: palette.yellow, Icon: FaCircle };
  }
}

function useActionIcon(action: string) {
  const { color, Icon } = useActionText(action);

  return <Icon color={color} size="1.5rem" />;
}

// const LegislationAction = styled.span.attrs<LegislationActionProps>(() => ({
//   children: <>useActionIcon(action)</>,
// }))<LegislationActionProps>(({ action }) => {
//   const { font } = useTheme();
//   const { color: textColor } = useActionText(action);
//   return css`
//     font-family: ${font.family.proximaNova};
//     color: ${textColor};
//     size: 1.125rem;
//     margin-right: 1rem;
//     align-self: center;
//   `;
// });

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const VoteSection = styled.div(() => {
  const { color, font } = useTheme();
  return css`
    font-family: ${font.family.proximaNova};
    font-weight: ${font.weights.light};
    font-size: 1.125rem;
    color: ${color.text.primary};
    text-align: center;
    margin-top: 0.5rem;
  `;
});

interface VoteTextProps {
  color?: string;
}

const VoteText = styled.span<VoteTextProps>(({ color = 'white' }) => {
  const { font } = useTheme();
  return { color, fontWeight: font.weights.semiBold };
});

const VoteYear = styled.div(() => {
  return css`
    text-align: right;
    font-size: 0.875rem;
  `;
});

function LegislationItem(props: LegislationItemProps) {
  const { name, topics, year, upDownVotes } = props;

  const action = props.action.toUpperCase();
  const { color, text } = useActionText(action);

  const $upDownVoteArea = upDownVotes ? (
    <UpDownVotes {...upDownVotes} />
  ) : (
    <UpDownVoteDemo />
  );

  return (
    <BoxContainer>
      <DesktopContainer>
        <Row>
          <Txt>
            {name}
            <Em style={{ marginLeft: '1rem' }}>
              {topics.map((topic) => `${topic} `)} {year && ` - ${year}`}
            </Em>
          </Txt>

          <Row>{$upDownVoteArea}</Row>
        </Row>
      </DesktopContainer>

      <MobileContainer>
        <TopRow>
          <PillList topics={topics} />
          {useActionIcon(action)}
        </TopRow>
        <VoteSection>
          Voted <VoteText color={color}>{text}</VoteText>{' '}
          {name && (
            <>
              on <VoteText>{name}.</VoteText>
            </>
          )}
        </VoteSection>
        <VoteYear>{year}</VoteYear>
        <UpDownVoteArea>{$upDownVoteArea}</UpDownVoteArea>
      </MobileContainer>
    </BoxContainer>
  );
}

export function Legislation({ activity }: LegislationProps): JSX.Element {
  const { currentData, sortRandom, sortData, ...paginationProps } =
    usePagination<LegislationItemProps>(activity, 3);

  const { nextPage, prevPage } = paginationProps;

  const sortByMostRecent = () => {
    // Need to make sure year is consistent, this is a fallback that will put legislation items with no year attached waay in the back
    sortData((a, b) => ((a.year || 0) > (b.year || 0) ? 1 : -1));
  };

  // const sortByMostVotes = () => {
  //   sortData((a, b) =>
  //     (a.upDownVotes?.upVotes || 0) > (b.upDownVotes?.upVotes || 0) ? 1 : -1
  //   );
  // };

  const $header = (
    <SectionHeader
      paginationProps={paginationProps}
      sortOptions={[
        {
          text: 'Most Recent',
          id: 'recent',
          callback: sortByMostRecent,
        },
        {
          text: 'Random',
          id: 'random',
          callback: sortRandom,
        },
      ]}
    />
  );

  return (
    <TitleSection
      title="Voting History"
      colorTheme="violet"
      header={$header}
      {...{ nextPage, prevPage }}
    >
      <GapContainer direction="column" gap="25px">
        {currentData().map((item) => (
          <LegislationItem {...item} key={item.id} />
        ))}
      </GapContainer>
    </TitleSection>
  );
}
