/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Cookies from 'js-cookie';
import { User, PasswordParams } from 'Context';
import { postWithToken, requestWithToken, post, request } from './rootServices';

interface AuthResponse {
  expiry: string;
  token: string;
  user: User;
}

export interface LoginAttrs {
  username: string;
  password: string;
}

// TODO: Change endpoint to accept {firstName, lastName, and zipCode} as required fields
export interface RegisterAttrs {
  email: string; // 254 characters
  firstName?: string; // 30 chars
  lastName?: string; // 150 chars
  password: string;
  zipCode?: string; // 10 chars (5 or 10 char ZIP)
}

export const loginUser = ({ username, password }: LoginAttrs) =>
  post<AuthResponse>('/api/auth/login/', {
    username,
    password,
  });

export const logoutUser = (clearState?: () => void) => {
  const service = postWithToken('/api/auth/logout/');
  Cookies.remove('token');
  return clearState
    ? service.finally(() => {
        clearState();
      })
    : service;
};

export const verifyUser = (id: string, token: string) =>
  request<{ success: boolean }>(`api/auth/register/verify/${id}/${token}/`);

export const getUser = () => requestWithToken<User>('api/auth/user/');

export const registerUser = (input: RegisterAttrs) =>
  post<AuthResponse>('/api/auth/register/', input);

export const updateUser = (userData: Partial<User>) =>
  requestWithToken<User>('api/auth/user/', {
    data: userData,
    method: 'PATCH',
  });

export const updatePassword = (passwordData: PasswordParams) =>
  requestWithToken<User>('api/auth/changepassword/', {
    data: passwordData,
    method: 'PATCH',
  });

export const destroyUser = () =>
  requestWithToken<User>('api/auth/user/', {
    method: 'DELETE',
  });
