import React from 'react';
import { TextInput, SButton } from 'Components';
import { useHistory } from 'react-router-dom';

export default function HomePage(): JSX.Element {
  const history = useHistory();

  return (
    <>
      <TextInput name="address" label="Please Enter Your Address" />
      <SButton primary onClick={() => history.push('/ballot')}>
        Enter
      </SButton>
    </>
  );
}
