import styled, { css } from 'styled-components';
import React from 'react';
import { FlagLoader } from 'Components';

const LoadingShade = styled.section(({ theme: { color } }) => {
  return css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.75);
    color: ${color.foreground.primary};
  `;
});

export function LoaderTakeover(): JSX.Element {
  return (
    <LoadingShade>
      <FlagLoader />
    </LoadingShade>
  );
}
