import React, { useState, useEffect, useCallback, ReactNode } from 'react';
import Cookies from 'js-cookie';
import { getUser, logoutUser, loginUser, registerUser } from './Services';
import { AuthContext, User, AuthModalState } from './Context';

interface AuthProviderProps {
  children: ReactNode;
}

export default function AuthProvider({
  children,
}: AuthProviderProps): JSX.Element {
  const [authModalState, setAuthModalState] = useState<AuthModalState>(null);
  const [pageName, setPageName] = useState<string | undefined>();

  const token = Cookies.get('token');

  const hasToken = !!token;

  const [user, setUser] = useState<User | null>(null);

  const clearState = useCallback(() => {
    setUser(null);
  }, []);

  const logout = useCallback(() => logoutUser(clearState), [clearState]);

  useEffect(() => {
    // If the user has a token but not user information, we need to grab that from API and add to state
    if (hasToken && !user) {
      getUser().then(
        (res) => {
          setUser(res?.data);
        },
        (error) => {
          console.error('getUser Error', error);
          // If we get an error, unauthenticate user by removing token and clearing state.
          logout();
        }
      );
    }
  }, [hasToken, setUser, logout, user]);

  const authState = {
    user,
    setUser,
    clearState,
    authModalState,
    setAuthModalState,
    logout,
    login: loginUser,
    register: registerUser,
    pageName,
    setPageName,
  };

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}
