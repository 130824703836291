import styled from 'styled-components';

export const ProfileSection = styled.section`
  padding: 1.5rem 0;
  border-bottom: 1px solid ${({ theme: { color } }) => color.foreground.primary};
  align-items: center;
  display: grid;
  @media only screen and (max-width: 1023px) {
    display: block;
  }
  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 2fr;
  }

  max-width: 60rem;
`;

export const FieldContainer = styled.div`
  text-align: center;
`;

export { PersonalInfo } from './PersonalInfo';
export { ChangePassword } from './ChangePassword';
export { DestroyAccount } from './DestroyAccount';
