import React from 'react';
import styled, { css } from 'styled-components';
import {
  TitleSection,
  BoxContainer,
  GapContainer,
  UpDownVotes,
  UpDownVotesProps,
  UpDownVoteDemo,
  Row,
  Em,
  Txt,
  SectionHeader,
} from 'Components';
import moment from 'moment';
import { FaEllipsisH } from 'react-icons/fa';

export interface Quote {
  id: string;
  topic: {
    name: string;
    id: string;
  };
  date: string;
  quote: string;
  source: string;
  upDownVotes: UpDownVotesProps;
}

export interface QuotesProps {
  quotes: Quote[];
}

export const Topic = styled.span(({ theme: { color, font } }) => {
  return css`
    background: ${color.foreground.primary};
    border-radius: 25px;

    font-family: ${font.family.commutersSans};
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    padding: 5px 25px;
    /* identical to box height */

    color: ${color.foreground.secondary};
    /* text-transform: uppercase; */
  `;
});

const QuoteText = styled.div(({ theme }) => {
  const { color, font } = theme;
  return css`
    font-family: ${font.family.proximaNova};
    font-size: 22px;
    line-height: 32px;
    color: ${color.text.secondary};
  `;
});

export function Quotes({ quotes }: QuotesProps): JSX.Element {
  const sortByMostRecent = () => {
    // setActiveButton('recent');
  };

  const sortByRandom = () => {
    // setActiveButton('random');
  };

  // const sortByMostVotes = () => {
  //   setActiveButton('mostVotes');
  // };

  const $header = (
    <SectionHeader
      sortOptions={[
        {
          text: `Most Recent`,
          id: `recent`,
          callback: sortByMostRecent,
        },
        {
          text: `Random`,
          id: `random`,
          callback: sortByRandom,
        },
        // {
        //   text: `Most Votes`,
        //   id: `mostVotes`,
        //   callback: sortByMostVotes,
        // },
      ]}
    />
  );
  return (
    <TitleSection title="Quotes" colorTheme="quote" header={$header}>
      {quotes.map(({ id, topic, date, quote, source, upDownVotes }) => {
        const dateString = moment(date).format('MMMM Do, YYYY');
        return (
          <BoxContainer padding="2rem" outline key={id}>
            <GapContainer gap="25px" direction="column">
              <Row>
                <Topic>{topic.name}</Topic>
                <Txt>{dateString}</Txt>
              </Row>

              <QuoteText>“{quote}</QuoteText>

              <Row>
                {upDownVotes ? (
                  <UpDownVotes {...upDownVotes} />
                ) : (
                  <UpDownVoteDemo />
                )}
                <Txt onClick={() => alert(source)}>
                  <Em>
                    Source <FaEllipsisH size="30px" />
                  </Em>
                </Txt>
              </Row>
            </GapContainer>
          </BoxContainer>
        );
      })}
    </TitleSection>
  );
}
