import React from 'react';
import { Candidate, CandidateProps, OutlineSection } from 'Components';
import styled from 'styled-components';

export interface CandidatesProps {
  candidates: CandidateProps[];
}

const Wrapper = styled.div`
  margin-right: 1rem;
`;

export function Candidates(props: CandidatesProps): JSX.Element {
  const { candidates } = props;
  return (
    <OutlineSection>
      {candidates.map((candidate) => (
        <Wrapper key={candidate.id}>
          <Candidate {...candidate} />
        </Wrapper>
      ))}
    </OutlineSection>
  );
}
