import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { SmallTitle } from '.';

export interface CommitteeProps {
  id: string | number;
  name: string;
}

export interface CommitteesProps {
  committees: CommitteeProps[];
}

export const Committee = styled.span(() => {
  const { font, color } = useTheme();
  return css`
    font-family: ${font.family.proximaNova};
    color: ${color.white};
    background: ${color.foreground.primary};
    border-radius: 50px;
    padding: 0.75rem;
    white-space: nowrap;
    margin: 0.5rem;
    cursor: default;
  `;
});

const CommitteeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media only screen and (max-width: 1023px) {
    justify-content: center;
  }
`;

export function Committees({ committees }: CommitteesProps): JSX.Element {
  return (
    <div>
      <SmallTitle center>Committees</SmallTitle>
      <CommitteeWrapper>
        {committees.map((c) => (
          <Committee style={{ color: 'white' }} key={c.id}>
            {c.name}
          </Committee>
        ))}
      </CommitteeWrapper>
    </div>
  );
}
