import React, { useState } from 'react';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import styled, { css, useTheme } from 'styled-components';

import { randomInt, randomBool } from 'Utils';

interface ThumbWrapperProps {
  highlight?: boolean;
}

const ThumbWrapper = styled.div<ThumbWrapperProps>(
  ({ highlight = false, theme: { color } }) => {
    return css`
      flex-basis: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${color.foreground.primary};
      border-radius: 20px;
      color: ${highlight ? color.background.secondary : color.text.primary};
      padding: 5px 10px;

      background: ${highlight ? color.foreground.primary : 'none'};
      > * {
        padding: 2px;
      }
      ${!highlight &&
      css`
        cursor: pointer;

        :hover {
          color: ${color.background.secondary};
          background: ${color.foreground.primary};
        }
      `}
    `;
  }
);

export interface UpDownVotesProps {
  upVotes: number;
  downVotes: number;
  votedUp?: boolean;
  votedDown?: boolean;
  upVote?: () => void;
  downVote?: () => void;
}

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const VoteText = styled.span(() => {
  const { font } = useTheme();
  return css`
    font-family: ${font.family.proximaNova};
    font-weight: ${font.weights.semiBold};
    font-size: 1.125rem;
    margin-left: 0.25rem;
  `;
});

export function UpDownVotes(props: UpDownVotesProps): JSX.Element {
  const { upVotes, downVotes, votedUp, votedDown, upVote, downVote } = props;
  return (
    <Wrapper>
      <ThumbWrapper highlight={!!votedUp} onClick={upVote}>
        <FaThumbsUp size="1.125rem" />
        <VoteText>{upVotes}</VoteText>
      </ThumbWrapper>
      <ThumbWrapper
        highlight={!!votedDown}
        onClick={downVote}
        style={{ marginLeft: '.75rem' }}
      >
        <FaThumbsDown size="1.125rem" />
        <VoteText>{downVotes}</VoteText>
      </ThumbWrapper>
    </Wrapper>
  );
}

export function UpDownVoteDemo(): JSX.Element {
  const [upVotes, setUpVotes] = useState(randomInt(0, 300));
  const [downVotes, setDownVotes] = useState(randomInt(0, 100));
  const [votedUp, setVotedUp] = useState(randomBool(0.25));
  const [votedDown, setVotedDown] = useState(
    votedUp ? false : randomBool(0.25)
  );

  const upVote = () => {
    setVotedUp(true);
    setVotedDown(false);
    if (!votedUp) setUpVotes(upVotes + 1);
    if (votedDown) setDownVotes(downVotes - 1);
  };

  const downVote = () => {
    setVotedUp(false);
    setVotedDown(true);
    if (!votedDown) setDownVotes(downVotes + 1);
    if (votedUp) setUpVotes(upVotes - 1);
  };

  return (
    <UpDownVotes
      {...{ upVotes, downVotes, votedUp, votedDown, upVote, downVote }}
    />
  );
}

export const UpDownVoteArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
`;
