import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

import { PartyAvatar, PartyAvatarProps, VerticalLine } from 'Components';
import { formatName } from 'Utils';

export interface CandidateProps extends PartyAvatarProps {
  id: string | number;
  isIncumbent?: boolean;
}

const SideText = styled.div(() => {
  const { color, font } = useTheme();
  return css`
    color: ${color.foreground.primary};
    font-family: ${font.family.commutersSans};
    letter-spacing: 0.05em;

    writing-mode: tb-rl; /* For backward compatability */
    writing-mode: vertical-rl;
    transform: rotate(180deg); /* so text reads from bottom up */

    font-size: 0.75rem;
    width: 15px;
    text-transform: uppercase;
    align-self: center;
    margin-right: 1rem;
  `;
});

const CandidateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const Name = styled.div(() => {
  const { color, font } = useTheme();
  return css`
    margin-top: 1rem;
    font-family: ${font.family.proximaNova};
    font-size: 1rem;
    color: ${color.text.primary};
  `;
});

export function Candidate(props: CandidateProps): JSX.Element {
  const { party, id, isIncumbent, ...avatarProps } = props;
  const history = useHistory();

  return (
    <>
      <CandidateWrapper>
        {isIncumbent && <SideText>Incumbent</SideText>}
        <div>
          <PartyAvatar
            onClick={() => history.push(`/politician/${id}`)}
            party={party}
            {...avatarProps}
          />
          <Name>{formatName(avatarProps.name)}</Name>
        </div>
      </CandidateWrapper>
      {isIncumbent && <VerticalLine marginRight="2rem" />}
    </>
  );
}
