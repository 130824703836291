import React, { Dispatch, SetStateAction } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import styled, { css, useTheme } from 'styled-components';

import { Row, PageIndex, DesktopContainer } from 'Components';

interface PageLinkProps {
  disabled: boolean;
}

export const PageLink = styled.a<PageLinkProps>(({ disabled }) => {
  const { color, font } = useTheme();
  return css`
    font-family: ${font.family.commutersSans};
    font-size: 1.125rem;
    line-height: 1.5;
    color: ${disabled ? color.palette.blueDark : color.text.primary};
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: ${disabled ? 'default' : 'pointer'};
    align-items: center;
  `;
});

export const LinkText = styled.span`
  border-bottom: 1px dotted;
  margin: 0 0.25rem;
`;

export interface PaginationProps {
  nextPage: (() => void) | null;
  prevPage: (() => void) | null;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  pageCount: number;
  items: (number | string)[];
}

export function Pagination(props: PaginationProps): JSX.Element {
  const { nextPage, prevPage, setCurrentPage, currentPage, pageCount, items } =
    props;

  const mode = pageCount > 10 ? 'numbers' : 'dots';

  return (
    <Row margin="1rem 2rem">
      <DesktopContainer>
        <PageLink
          disabled={currentPage === 1}
          onClick={prevPage ? prevPage : undefined}
        >
          <FaChevronLeft />
          <LinkText>Prev</LinkText>
        </PageLink>
      </DesktopContainer>

      <PageIndex
        mode={mode}
        items={items}
        siblingCount={0}
        {...{ pageCount, currentPage, setCurrentPage }}
      />

      <DesktopContainer style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <PageLink
          disabled={currentPage === pageCount}
          onClick={nextPage ? nextPage : undefined}
        >
          <LinkText style={{ display: 'flex', justifyContent: 'flex-end' }}>
            Next
          </LinkText>
          <FaChevronRight />
        </PageLink>
      </DesktopContainer>
    </Row>
  );
}
