/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import {
  logoSVG,
  ballotMeasureMVP,
  desktopBallot,
  mobileNews,
  connections,
} from 'Images';

import './styles.css';
import { useAuth } from 'Context';

import {
  Section1,
  Section1b,
  Section2,
  Section3,
  Section4,
} from './sharedComponents';

const Logo = styled.img.attrs(() => ({
  src: logoSVG,
  alt: '🇺🇸Populist',
}))`
  height: 83px;
  @media only screen and (max-width: 960px) {
    width: 85%;
    height: auto;
  }
`;

const MVPGif = styled.img.attrs(() => ({
  src: ballotMeasureMVP,
  alt: 'The Colorado ballot measure prototype on mobile.',
}))`
  width: 100%;
  height: auto;
  vertical-align: middle;
`;

const DesktopBallotImg = styled.img.attrs(() => ({
  src: desktopBallot,
  alt: 'The ballot page on desktop.',
}))`
  width: 100%;
  vertical-align: middle;
`;

const MobileNewsImg = styled.img.attrs(() => ({
  src: mobileNews,
  alt: 'The news page on mobile.',
}))`
  width: 100%;
  height: auto;
  vertical-align: middle;
`;

const ConnectionsImg = styled.img.attrs(() => ({
  src: connections,
  alt: 'Creating connections between voters, organizations, and politicians.',
}))`
  width: 100%;
  max-width: 1000px;
  margin-bottom: 30px;
`;

const Content = styled.div`
  padding: 80px 100px 80px 80px;
  max-width: 770px;
  @media only screen and (max-width: 960px) {
    padding: 40px;
  }
`;

interface ContentWideProps {
  last?: boolean;
}

const ContentWide = styled(Content)<ContentWideProps>(({ last }) => {
  return css`
    max-width: 1000px;
    ${last && 'padding-top: 60px'}
  `;
});

const Em = styled.span`
  font-weight: 600;
  font-style: italic;
`;

const useLandingButtonStyles = () => css`
  display: inline-block;
  background-color: #ffe45c;
  font-family: 'Commuters Sans';
  font-size: 16px;
  font-weight: 600;
  padding: 0px 40px;
  border-radius: 40px;
  margin: 10px 0px;
  line-height: 50px;
  color: #002135;
  min-width: 230px;

  :hover {
    background-color: #e4cc52;
    cursor: pointer;
  }
`;

const Button = styled.button(useLandingButtonStyles);
const Submit = styled.input.attrs(() => ({ type: 'submit' }))(
  useLandingButtonStyles
);

export default function LandingPage(): JSX.Element {
  const { setAuthModalState } = useAuth();
  const openAuth = () => setAuthModalState('register');

  return (
    <div id="container1">
      <div id="menu">
        <ul className="nav">
          <li>
            <Link to="/ballot">
              <Button>COLORADO BALLOT MEASURES</Button>
            </Link>
          </li>
        </ul>
      </div>

      <Section1>
        <Content>
          <Logo />
          <h1>Non-partisan politics for the people.</h1>
          <p>We believe in people.</p>
          <p>
            In transparent, non-partisan, accessible information. In a
            well-informed and critical public. In a more equitable democracy for
            all.
          </p>
          <p>
            People are hungry for a new way of engaging with politics, and we
            believe local is the place to start. Party politics matter less when
            it's your backyard.
          </p>
          <p>Join us in making technology work for the people again.</p>
        </Content>
      </Section1>

      <Section1b>
        <div id="s1bcol1">
          <ContentWide>
            <h1>It's time to vote.</h1>
            <p>
              Live in Colorado? Check out our 2022 Colorado statewide ballot
              measure guide.
            </p>
            <Button onClick={openAuth}>ACCESS THE PROTOTYPE</Button>
          </ContentWide>
        </div>
        <div id="s1bcol2">
          <Link to={`/ballot`}>
            <MVPGif />
          </Link>
        </div>
      </Section1b>

      <Section2>
        <div id="s2col1">
          <DesktopBallotImg />
        </div>
        <div id="s2col2">
          <ContentWide>
            <h1>It begins at the ballot.</h1>
            <p>
              Voting is the baseline action we can take as citizens. That's why
              we're focused on providing unbiased, transparent ballot
              information in a clear, beautiful design.
            </p>
          </ContentWide>
        </div>
      </Section2>

      <Section3>
        <div id="s3col1">
          <ContentWide>
            <h1>But it doesn't end there.</h1>
            <p>
              Our platform also makes it easy to stay informed about local,
              state, and federal news with automatic filters.
            </p>
          </ContentWide>
        </div>
        <div id="s3col2">
          <MobileNewsImg />
        </div>
      </Section3>

      <Section4>
        <div id="s4row1">
          <ContentWide>
            <ConnectionsImg />
            <h1>
              Populist is for <Em>voters</Em>, <Em>organizations</Em>, and
              <Em>politicians</Em>.
            </h1>
            <p>
              By creating and surfacing connections between these key
              stakeholders, we can make meaningful action more accessible and
              build a stronger democracy.
            </p>
          </ContentWide>
        </div>
        <div id="s4row2">
          <ContentWide last>
            <h1 className="botspace">Interested? Sign up to learn more.</h1>

            <div id="mc_embed_signup">
              <form
                action="https://populist.us2.list-manage.com/subscribe/post?u=f24fbd8a9a3cd91b78cb4a457&amp;id=361b16ea14"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  <input
                    type="email"
                    name="EMAIL"
                    className="email"
                    id="mce-EMAIL"
                    placeholder="Email address"
                    required
                  />

                  <div
                    style={{ position: 'absolute', left: '-5000px' }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_f24fbd8a9a3cd91b78cb4a457_361b16ea14"
                    />
                  </div>
                  <div>
                    <Submit
                      value="SUBMIT"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                    />
                  </div>
                </div>
              </form>
            </div>
          </ContentWide>
        </div>
      </Section4>

      <div id="footer">
        <p id="social">
          <a
            href="https://www.facebook.com/populist.us"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaFacebook className="link" />
          </a>
          <a
            href="https://twitter.com/populist_us"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaTwitter style={{ margin: '0px 30px' }} className="link" />
          </a>
          <a
            href="https://www.instagram.com/populist.us/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaInstagram className="link" />
          </a>
        </p>

        <p>
          <Link to="/ballot">
            <Button>COLORADO BALLOT MEASURES</Button>
          </Link>
        </p>

        <p id="copyright">&#169; 2022 Populist. &nbsp;Made in Colorado.</p>
      </div>
    </div>
  );
}
