import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAddressData, useAddressAutocomplete } from 'Hooks';
import { useAuth } from 'Context';
import { RegisterAttrs } from 'Services';
import {
  DataListInput,
  GapContainer,
  SButton,
  TextInput,
  emailExp,
  FlagLoader,
} from 'Components';

import { HIDE_ADDRESS } from 'Flags';

import { ProfileSection, FieldContainer } from './shared';

interface PersonalInfoProps {
  flexDirection: 'row' | 'column';
  loading: boolean;
  handleSave: (userData: RegisterAttrs) => void;
}

export function PersonalInfo({
  flexDirection,
  loading,
  handleSave,
}: PersonalInfoProps): JSX.Element {
  const { handleSubmit, register, setValue, formState } =
    useForm<RegisterAttrs>({ mode: 'onChange' });

  const { errors } = formState;

  const [addressInputValue, setAddressInputValue] = useState('');
  const [verifiedAddress, setVerifiedAddress] = useState(null);
  const suggestions = useAddressAutocomplete(addressInputValue);
  const addressData = useAddressData(verifiedAddress);

  const handleAddress = (e?: React.FormEvent<HTMLInputElement>) => {
    setAddressInputValue(e?.currentTarget.value ?? '');
    if (suggestions?.map((s) => s.label).includes(e?.currentTarget.value)) {
      setVerifiedAddress(suggestions[0]);
    }
  };

  useEffect(() => {
    if (addressData) {
      const { zipcode, plus4_code: plus4code } = addressData[0].components;
      setValue('zipCode', `${zipcode}-${plus4code}`, { shouldDirty: true });
    }
  }, [addressData, setValue]);

  const { user } = useAuth();
  const { firstName, lastName, email, zipCode } = user || {};

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        {/* <ProfileSection>
          <h1>Profile picture</h1>
          <FieldContainer>
            <SButton
              outlined
              fullWidth
              onClick={() => {
                alert('save');
              }}
            >
              Upload photo
            </SButton>
            <SButton
              outlined
              fullWidth
              onClick={() => {
                alert('save');
              }}
            >
              Edit thumbnail
            </SButton>
          </FieldContainer>
        </ProfileSection> */}
        <ProfileSection>
          <h1>Name</h1>
          <FieldContainer>
            <GapContainer direction={flexDirection} fullWidth>
              <TextInput
                {...register('firstName', { required: true })}
                defaultValue={firstName}
                error={errors.firstName}
                label="First Name"
              />
              <TextInput
                {...register('lastName', { required: true })}
                defaultValue={lastName}
                error={errors.lastName}
                label="Last Name"
              />
            </GapContainer>
            <SButton
              type="submit"
              outlined
              fullWidth
              disabled={
                !formState.dirtyFields.firstName &&
                !formState.dirtyFields.lastName
              }
            >
              <GapContainer direction="row">
                {loading ? <FlagLoader height="16px" /> : 'Save'}
              </GapContainer>
            </SButton>
          </FieldContainer>
        </ProfileSection>

        {!HIDE_ADDRESS && (
          <ProfileSection>
            <h1>Zipcode</h1>
            <FieldContainer>
              <p style={{ textAlign: 'left' }}>
                Enter the address where you&#39;re registered to vote and
                we&#39;ll find your 9 digit zip code for you. That&#39;s the
                only thing we save, so we can serve you relevant political
                information.
              </p>
              <GapContainer direction={flexDirection} fullWidth>
                <DataListInput
                  items={suggestions}
                  label="Address"
                  value={addressInputValue}
                  onChange={handleAddress}
                  showError={false}
                />
                <TextInput
                  {...register('zipCode', { required: true })}
                  label="Zipcode"
                  error={errors.zipCode}
                  defaultValue={zipCode}
                  readOnly
                />
              </GapContainer>
              <SButton
                type="submit"
                outlined
                fullWidth
                disabled={!formState.dirtyFields.zipCode}
              >
                Save
              </SButton>
            </FieldContainer>
          </ProfileSection>
        )}

        <ProfileSection>
          <h1>Email</h1>
          <FieldContainer>
            <TextInput
              {...register('email', { required: true })}
              defaultValue={email}
              error={errors.email}
              label="Email"
              pattern={emailExp}
            />
            <SButton
              type="submit"
              outlined
              fullWidth
              disabled={!formState.dirtyFields.email}
            >
              Save
            </SButton>
          </FieldContainer>
        </ProfileSection>
      </form>
    </>
  );
}
